import React from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import {
  Badge,
  Block,
  Carousel,
  Container,
  Cards,
  Card,
  TrustBox,
  Banner
} from "@components"
import ModuleArea from "../helpers/moduleArea"
import { promoChecker, filterByLocale } from "../helpers/miscHelpers"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { InView } from "react-intersection-observer"

const IndexPage = ({
  data: {
    datoCmsSite,
    home: {
      homepageCarousel,
      showPathways,
      pathways,
      productCarousel,
      content,
      seoMetaTags,
      heading
    },
    globalConfig: { defaultPathways, trustMarks },
    promotions
  }
}) => {
  const allPathways = [...pathways, ...defaultPathways].slice(0, 3)

  const promos = filterByLocale(promotions.nodes)

  const promoBanners = promos.map(promo => {
    if (promoChecker(promo.startDate, promo.endDate, promo.locale))
      return {
        id: promo.id,
        image: promo.banner,
        mobileImage: promo.mobileBanner,
        link: promo.pageLink
          ? {
              link: {
                path: promo.pageLink.path
              }
            }
          : null
      }
    else return null
  })
  promoBanners && homepageCarousel.unshift(...promoBanners.filter(Boolean))

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block guttersReverse padding="both-small-no-top">
        <Container>
          <Carousel
            moduleStyle="hero"
            items={homepageCarousel}
            loading="eager"
          />
        </Container>
      </Block>
      {showPathways && (
        <Block padding="both" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
      <Block padding="double" heading={heading} isH1>
        <Cards orientation="horizontal" columns="4" size="large">
          {trustMarks.map(({ icon: { url, alt }, id, heading }) => (
            <Badge
              image={url}
              alt={alt}
              key={`trustmark-${id}`}
              heading={heading}
              moduleStyle="trustmark"
            />
          ))}
        </Cards>
      </Block>
      <Block gutters>
        <Container>
          <Block gutters background="white" padding="top">
            <InView>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <TrustBox horizontal inView={inView} />
                </div>
              )}
            </InView>
          </Block>
        </Container>
      </Block>

      <Block padding="double-top-single-bottom">
        <Container>
          <Carousel
            heading="Featured Products"
            moduleStyle="products"
            items={productCarousel}
            showThumbNavigation
          />
        </Container>
      </Block>
      {content && (
        <Block>
          <ModuleArea modules={content} />
        </Block>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery($language: String!) {
    datoCmsSite {
      name
    }
    home: datoCmsHome(locale: { eq: $language }) {
      showPathways
      heading
      ...HomeContentModuleArea
      pathways {
        ...Card
      }
      ...CarouselProductSlide
      ...CarouselHomeImageSlide

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
      trustMarks {
        ...TrustMark
      }
    }
    promotions: allDatoCmsPromotion(filter: { locale: { eq: $language } }) {
      nodes {
        id
        name
        startDate
        endDate
        locale
        banner {
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "550"
              fit: "crop"
              w: "1170"
              q: 60
              auto: "format"
            }
          )
        }
        mobileBanner {
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "457"
              fit: "crop"
              w: "375"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        pageLink {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsCollection {
            id
            path
          }
        }
      }
    }
  }
`
