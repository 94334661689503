import React, { useState } from "react"
import { Block, Testimonial, Button } from "@components"
import { useStaticQuery, graphql } from "gatsby"
import { filterByCountry, filterByLocale } from "@helpers/miscHelpers"

const TestimonialList = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsTestimonial(sort: { fields: position, order: ASC }) {
        nodes {
          id
          quote
          country
          credit
          locale
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "320"
                fit: "crop"
                w: "560"
                q: 60
                auto: "format"
              }
            )
            alt
          }
          video {
            url
            title
            provider
            providerUid
          }
        }
      }
    }
  `)

  const [visibleItems, setVisibleItems] = useState(10)

  const handleLoadMore = e => {
    e.preventDefault()
    setVisibleItems(prevVisibleItems => prevVisibleItems + 10)
  }

  const filteredByLocale = filterByLocale(data.allDatoCmsTestimonial.nodes)
  const list = filterByCountry(filteredByLocale)

  return (
    <Block gutters>
      {list.length > 0 &&
        list.slice(0, visibleItems).map(item => {
          return <Testimonial {...item} />
        })}
      {list.length > visibleItems && (
        <Block gutters padding="double">
          <Button
            type="button"
            onClick={handleLoadMore}
            children="Load More"
            fullWidthMobile
            block
            short
          />
        </Block>
      )}
    </Block>
  )
}

export default TestimonialList

export const query = graphql`
  fragment TestimonialList on DatoCmsTestimonialsList {
    padding
    model {
      apiKey
    }
    linkHash
    __typename
  }
`
