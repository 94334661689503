import React, { useContext, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Disclaimer, Swatch } from "@components"
import style from "./swatches.mod.scss"
import { motion, AnimatePresence } from "framer-motion"
import ProductContext from "@context/productContext"
import { getOptionsMap, getCurrentRenderFromVariant } from "@helpers/variants"

const Swatches = ({
  placeholder,
  heading,
  lead,
  isWide,
  renders = [],
  modifiers = [],
  items = [],
  defaultVariant,
  colourDisclaimerNode,
  showColourDisclaimer
}) => {
  modifiers.push(style["swatches--wide-product"])
  const [currentVariant, setCurrentVariant] = useState(defaultVariant)
  const [currentRender, setCurrentRender] = useState(placeholder)
  const optionTypes = new Map(items.map(({ id, heading }) => [id, heading]))
  const currentValues =
    currentVariant && getOptionsMap(currentVariant.specifics)

  const { productLoading, setOption, validOptions, currentModel } = useContext(
    ProductContext
  )

  // Update table state when product comes online.
  useEffect(() => {
    if (!productLoading) {
      setCurrentVariant(currentModel)
      if (renders.length > 1) {
        const render = getCurrentRenderFromVariant(
          renders,
          currentModel,
          optionTypes
        )

        render?.image && setCurrentRender(render)
      }
    }
  }, [productLoading, currentModel, optionTypes, renders])

  return (
    <div className={[style.swatches, ...modifiers].join(" ")}>
      <div className={style.swatches__intro}>
        <h2>{heading}</h2>
        <p>{lead}</p>
      </div>
      <div className={style.swatches__render}>
        <AnimatePresence>
          <motion.div
            className={style["swatches__render-image"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.6 } }}
            exit={{ opacity: 0 }}
            key={`swatches-render-image-${currentRender?.id}`}
          >
            <GatsbyImage
              objectFit="contain"
              image={currentRender?.image?.gatsbyImageData}
            />
          </motion.div>
        </AnimatePresence>
      </div>
      {items.length > 0 &&
        items.map(({ swatches, heading }) => {
          if (swatches.length < 1) return null
          const validValues = !productLoading && validOptions.get(heading)

          return (
            <div key={`swatch-${heading}`} className={style.swatches__wrap}>
              <h3>{`${heading}s`}</h3>
              <div className={style.swatches__list}>
                {swatches.map(item => {
                  const isSelected =
                    currentValues.get(heading) === item.name || false

                  const disabled =
                    productLoading || validValues.indexOf(item.name) < 0

                  return (
                    <div
                      key={`swatch-${item.id}`}
                      className={style.swatches__item}
                    >
                      <Swatch
                        swatch={item.swatch}
                        name={item.name}
                        value={item.name}
                        disabled={disabled}
                        isChecked={isSelected}
                        onChange={({ target }) =>
                          !productLoading && setOption(heading, target.name)
                        }
                      />
                    </div>
                  )
                })}
              </div>
              <div className={style.swatches__disclaimer}>
                {showColourDisclaimer && (
                  <Disclaimer content={colourDisclaimerNode} />
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Swatches

export const query = graphql`
  fragment SwatchesRender on DatoCmsProductRender {
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {
          h: "485"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "820"
          q: 60
          auto: "format"
        }
      )
      alt
    }
    colourOne: shell {
      name
      id
    }
    colourTwo: cabinet {
      name
      id
    }
    product {
      sku
    }
  }
`
