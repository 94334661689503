import React from "react"
import { Block, Card, Grid, Button, VideoCard } from "@components"
import { connectInfiniteHits } from "react-instantsearch-dom"

const InfiniteSearchResultsGrid = ({
  hits,
  refineNext,
  hasMore,
  query,
  onClick
}) => {
  // returns false if string is empty
  const regex = new RegExp(/^(?!\s*$).+/)
  const hasResults = regex.test(query) && hits && hits.length !== 0

  return (
    <div>
      {!hasResults && (
        <center>{`Sorry, no results found. Please try again.`}</center>
      )}
      {hasResults && (
        <>
          <Grid moduleStyle={"2-up-grid"}>
            {hits.map(item => {
              if (item.video) {
                return (
                  <VideoCard
                    key={`search-video-card--${item.objectID}`}
                    caption={item.description}
                    heading={item.name}
                    overlayImage={item.image}
                    onClick={onClick}
                    elevated
                    video={item.video}
                    isLearnCard
                    meta={item.meta}
                  />
                )
              } else {
                return (
                  <Card
                    key={`search-card--${item.objectID}`}
                    heading={item.name}
                    image={item.image}
                    link={{ url: item.url, title: `Learn More` }}
                    caption={item.excerpt}
                    truncate={3}
                    elevated
                    isClickable
                    isLearnCard
                    itemId={item.objectID}
                  />
                )
              }
            })}
          </Grid>
          {hasMore && (
            <Block padding={`double`} gutters>
              <Button
                type="button"
                onClick={refineNext}
                children="Load More"
                fullWidthMobile
                block
                short
              />
            </Block>
          )}
        </>
      )}
    </div>
  )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteSearchResultsGrid)

const SearchResultsGrid = ({ defaultRefinement, onClick }) => {
  return (
    <Block>
      <CustomInfiniteHits query={defaultRefinement} onClick={onClick} />
    </Block>
  )
}

export default SearchResultsGrid
