import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./card.mod.scss"
import { Button, Modal, Pill, Superscript, CardTags } from "@components"
import uniqid from "uniqid"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { ConditionalLink } from "@helpers/miscHelpers"
import Play from "@svgs/play.svg"

const Card = ({
  heading,
  subHeading,
  caption,
  image,
  link,
  brand,
  salesFeatures = [],
  onClick,
  links = [],
  modifiers = [],
  elevated = false,
  borderless = false,
  pathway = false,
  video = false,
  pill = false,
  value = false,
  defaultModel = false,
  selected = false,
  smallForMobile = false,
  padImage,
  hover,
  model,
  truncate,
  disabled,
  smallHeading = false,
  upsellVideo,
  isJacuzzi = false,
  isClickable = false,
  isLearnCard = false,
  itemId,
  file,
  simple = false,
  content = null
}) => {
  if (link) links.push(link)

  elevated && modifiers.push(style["card--elevated"])
  borderless && modifiers.push(style["card--borderless"])
  pathway && modifiers.push(style["card--pathway"])

  hover && modifiers.push(style["card--hover"])
  pill && modifiers.push(style["card--pill"])
  simple && modifiers.push(style["card--simple"])

  selected && modifiers.push(style["card--selected"])
  padImage && modifiers.push(style["card--pad-image"])
  smallForMobile && modifiers.push(style["card--small-for-mobile"])
  model && modifiers.push(style["card--model"])
  truncate && modifiers.push(style[`card--truncate-${truncate}-lines`])
  disabled && modifiers.push(style[`card--disabled`])
  smallHeading && modifiers.push(style[`card--small-heading`])
  isJacuzzi && modifiers.push(style[`card--jacuzzi`])
  isClickable && modifiers.push(style[`card--clickable`])

  const productCardConfig = {
    className: [style["card"], ...modifiers].join(" ")
  }

  const isSvg = typeof image === "string" && image.search(/.svg/g) > -1

  return (
    <div {...productCardConfig}>
      {image && (
        <ConditionalLink
          condition={file || (link && !isClickable)}
          config={
            (onClick,
            {
              to:
                file?.url ||
                (links.length > 0 && (links[0]?.path || links[0]?.url)),
              title: heading
            })
          }
        >
          <>
            <div className={style.card__image}>
              {isSvg ? (
                <img src={image} alt={image.alt || ""} />
              ) : (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  loading={"lazy"}
                  alt={image.alt || ""}
                />
              )}
              {brand && (
                <span className={style.card__brand}>
                  <img alt={brand.name} src={brand.logo.url} />
                </span>
              )}
              {video && (
                <Modal
                  video={video}
                  icon={<Play />}
                  ariaLabel={`Open modal and play video: ${video.title}`}
                  gtm={{
                    event: `video-engagement`,
                    label: `Video Model Click`,
                    value: `${video.title}`
                  }}
                />
              )}
              {defaultModel && (
                <div className={style.card__popular}>
                  <Pill icon={<FaStar />}>{"Most Popular"}</Pill>
                </div>
              )}
            </div>
          </>
        </ConditionalLink>
      )}
      <div className={style.card__content}>
        {isLearnCard && <CardTags article id={itemId} />}
        <ConditionalLink
          condition={link && !isClickable}
          config={
            (onClick,
            { to: links.length > 0 && (links[0]?.path || links[0]?.url) })
          }
        >
          {heading && (
            <div className={style["card__heading-wrap"]}>
              <h3
                className={style.card__heading}
                children={<Superscript children={heading} />}
              />
              {value && (
                <div
                  className={style.card__value}
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              )}
            </div>
          )}
        </ConditionalLink>
        {subHeading && (
          <p
            className={style.card__subHeading}
            children={<Superscript children={subHeading} />}
          />
        )}
        {caption && (
          <div
            className={style.card__lead}
            children={<Superscript children={caption} />}
          />
        )}
        {content && (
          <div
            className={style.card__text}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
        {!simple && (
          <>
            {salesFeatures && salesFeatures.length > 0 && (
              <ul className={style.card__salesList}>
                {salesFeatures.map(item => {
                  return (
                    <li
                      className={style.card__salesItem}
                      key={`sales-feature-${item.id}`}
                    >
                      <FaCheck /> <span>{item.description}</span>
                    </li>
                  )
                })}
              </ul>
            )}
          </>
        )}
        {links.length > 0 && !hover && (
          <div className={style["card__cta-wrapper"]}>
            {links.map(({ path, title = "More", url }) => (
              <div key={uniqid()} className={style.card__cta}>
                <Button
                  to={path || url}
                  title={title}
                  fullWidthMobile
                  secondary={pathway ? true : false}
                  short
                  isNested={isClickable ? true : false}
                >
                  {title}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
      {upsellVideo && (
        <div className={style.card__upsell}>
          <Modal
            video={upsellVideo}
            icon={<Play />}
            upsellVideo
            ariaLabel={`Open modal and play video: ${upsellVideo.title}`}
            gtm={{
              event: `video-engagement`,
              label: `Video Model Click`,
              value: `${upsellVideo.title}`
            }}
          />
        </div>
      )}
      {pill && <div className={style.card__pill}>{pill}</div>}
    </div>
  )
}

export default Card

export const query = graphql`
  fragment BlogCard on DatoCmsBlogPost {
    id
    title
    path
    excerptNode {
      childMarkdownRemark {
        html
      }
    }
    heroImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "265", fit: "crop", w: "500", q: 60, auto: "format" }
      )
      alt
    }
    __typename
  }

  fragment PageCard on DatoCmsPage {
    id
    title
    path
    excerptNode {
      childMarkdownRemark {
        html
      }
    }
    heroImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "265", fit: "crop", w: "500", q: 60, auto: "format" }
      )
      alt
    }
    __typename
  }

  fragment Card on DatoCmsPathway {
    id
    heading
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "340", fit: "crop", w: "370", q: 60, auto: "format" }
      )
      alt
    }
    link {
      ...Link
    }
    __typename
  }

  fragment Tile on DatoCmsTile {
    id
    heading
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "340", fit: "crop", w: "370", q: 60, auto: "format" }
      )
      alt
    }
    link {
      ...Link
    }
    __typename
  }

  fragment AwardCard on DatoCmsBadge {
    id
    heading
    image {
      url
    }
    locale
    __typename
  }

  fragment DownloadCard on DatoCmsManual {
    id
    heading
    text
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "120", fit: "crop", w: "120", q: 60, auto: "format" }
      )
      alt
    }
    file {
      url
    }
    fileOverride {
      ... on DatoCmsDocAsset {
        id
        item {
          url
        }
      }
      ... on DatoCmsDocUrl {
        id
        url
      }
    }
    locale
    __typename
  }

  fragment PeopleCard on DatoCmsPerson {
    id
    name
    jobTitle
    video {
      url
      provider
      providerUid
      thumbnailUrl
    }
    captionNode {
      childMarkdownRemark {
        html
      }
    }
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "121", fit: "crop", w: "121", q: 60, auto: "format" }
      )
      alt
    }
    __typename
    country
    locale
  }

  fragment CollectionCard on DatoCmsCollection {
    id
    locale
    name
    path
    excerptNode {
      childMarkdownRemark {
        html
      }
    }
    slug
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "357", fit: "crop", w: "501", q: 60, auto: "format" }
      )
      alt
    }
    model {
      apiKey
    }
    __typename
    salesFeatures {
      description
      id
    }
    tag {
      ... on DatoCmsBrand {
        ...BrandTag
      }
      ... on DatoCmsUsage {
        ...UsageTag
      }
      ... on DatoCmsSize {
        ...SizeTag
      }
      ... on DatoCmsPrice {
        ...PriceTag
      }
      ... on DatoCmsTag {
        ...TagTag
      }
    }
  }

  fragment SpecificsCard on DatoCmsSpecificsCard {
    id
    name
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    specificValue

    value
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {
          h: "357"
          fit: "fill"
          fill: "solid"
          fillColor: "f1f1f1"
          w: "501"
          q: 60
          auto: "format"
        }
      )
      alt
    }
    model {
      apiKey
    }
    __typename
    salesFeatures {
      description
      id
    }
  }
`
