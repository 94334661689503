import React, { useContext, useState, useEffect, useRef } from "react"
import config from "@config/website"
import { graphql, navigate } from "gatsby"
import CartContext from "@context/cartContext"
import ProductContext from "@context/productContext"
import { isBrowser } from "@context/themeContext"
import { ProductFinanceDisclaimer } from "../../templates/product"
import style from "./product-intro.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Scroll from "@helpers/scroll"
import {
  Breadcrumbs,
  Block,
  Price,
  Button,
  Container,
  Recommendation,
  Superscript,
  Tooltip
} from "@components"
import IconJets from "@svgs/jets.svg"
import IconDimensions from "@svgs/dimensions.svg"
import IconSeats from "@svgs/seats.svg"
import BadgeViewerIcon from "@svgs/badge-viewer.svg"
import Clearlift from "@svgs/clearlift-small.svg"
import FreeDelivery from "@svgs/free-delivery.svg"
import MotorisedLouvres from "@svgs/motorised-louvres.svg"
import useAddToCart from "@helpers/useAddToCart"
import { gtmPush } from "@helpers/gtmHelpers"

const ProductIntro = ({
  breadcrumbs,
  name,
  sku,
  disclaimer,
  heroImage,
  brand,
  finance,
  sectionNav,
  category,
  isWide,
  showImageDisclaimer,
  id,
  links,
  virtualConsultation,
  excerpt,
  parentPrice,
  has3dViewer,
  freeDelivery,
  motorised,
  clearliftCompatible,
  ...product
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const modifiers = []
  isWide && modifiers.push(style["product-intro--wide"])

  const productCat = category?.title
    ?.substring(0, category.title.length - 1)
    ?.toLowerCase()

  const { addToCart } = useAddToCart()
  const { cart, cartLoading } = useContext(CartContext)
  const { productLoading, currentModel } = useContext(ProductContext)

  const parentInCart = !cartLoading && cart.has(sku)
  const cartProduct = parentInCart && cart.get(sku)
  const modelInCart =
    !cartLoading && !productLoading && cartProduct
      ? cartProduct.variantSku === currentModel
      : false

  const linksArr = []
  links.length > 0 &&
    links.map(link => {
      if (!link?.link?.path && !link.url) return

      const isGetAQuote = link?.link?.path.includes(`get-a-quote`)
      const isWetTest = link?.link?.path.includes(`book-a-wet-test`)
      const isVirtualConsultation = (link?.link?.path || link.url).includes(
        virtualConsultation
      )

      let formProductData = {}

      if (isWetTest) {
        formProductData = {
          heading: name,
          image: heroImage,
          brand,
          excerpt,
          productName: name,
          productBrand: brand.title,
          productCategory: category.title
        }
      }

      if (isGetAQuote) {
        formProductData = {
          heading: name,
          image: heroImage.gatsbyImageData,
          excerpt
        }
      }

      const config = (isWetTest || isVirtualConsultation || isGetAQuote) && {
        onClick:
          (isWetTest || isGetAQuote) &&
          (e => {
            e.preventDefault()
            navigate(link.link.path, {
              state: {
                heading: name,
                image: heroImage,
                brand,
                excerpt,
                productName: name,
                productBrand: brand.title,
                productCategory: category.title,
                ...formProductData
              }
            })
          })
      }

      if (isWetTest) config.gaTarget = "intro-wet-test-button"
      if (isVirtualConsultation)
        config.gaTarget = "intro-virtual-consultation-button"
      if (isGetAQuote) config.gaTarget = "intro-get-a-quote-button"

      return linksArr.push({
        to: link?.link?.path || link.url,
        children: link.displayText,
        id: link.id,
        ...config
      })
    })

  const [isStickyNav, setStickyNav] = useState(false)
  const [stickyNavOffset, setStickyNavOffset] = useState()
  const navModifiers = []

  const productNav = useRef()

  isStickyNav && navModifiers.push(style["product-intro--nav-sticky"])

  const hasIcons = freeDelivery || clearliftCompatible || motorised

  useEffect(() => {
    const navbar = productNav.current
    setStickyNavOffset(navbar.offsetTop)
  }, [])

  if (isBrowser()) {
    window.onscroll = function() {
      myFunction()
    }
  }

  function myFunction() {
    if (window.pageYOffset >= stickyNavOffset) {
      setStickyNav(true)
    } else {
      setStickyNav(false)
    }
  }

  return (
    <div
      id="product-intro"
      className={[style["product-intro"], ...modifiers].join(" ")}
    >
      <Container>
        <Block gutters>
          {breadcrumbs && (
            <div className={style["product-intro__breadcrumbs"]}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          )}
          {name && (
            <h1
              className={style["product-intro__name"]}
              children={<Superscript children={name} />}
            />
          )}
          <div className={style["product-intro__hero"]}>
            {hasIcons && (
              <div className={style["product-intro__icons"]}>
                {clearliftCompatible && <Clearlift />}
                {freeDelivery && <FreeDelivery />}
                {motorised && <MotorisedLouvres />}
              </div>
            )}
            {heroImage ? (
              <GatsbyImage
                className={style["product-intro__hero-image"]}
                loading="eager"
                image={heroImage.gatsbyImageData}
              />
            ) : (
              <div
                className={style["product-intro__placeholder"]}
              >{`Missing Image`}</div>
            )}

            <div className={style["product-intro__wrap"]}>
              {brand && (
                <div className={style["product-intro__brand"]}>
                  <img alt={brand.name} src={brand.logo.url} />
                </div>
              )}

              <Recommendation productId={id} moduleStyle="bottom-right" />
            </div>

            {showImageDisclaimer && disclaimer && (
              <div className={style["product-intro__disclaimer"]}>
                {disclaimer}
              </div>
            )}
          </div>

          <div className={style["product-intro__seperator"]} />

          <div className={style["product-intro__price"]}>
            <Price prices={parentPrice} isAggregate={true} />
          </div>
          {finance?.length > 0 && <ProductIntroFinance finance={finance} />}

          <div className={style["product-intro__primary-actions"]}>
            {product.netoProductType === "configurable" ? (
              <Button gaTarget={"intro-customize-button"} to="#customise" wide>
                {productCat ? `Build your ${productCat}` : `Build your product`}
              </Button>
            ) : (
              <>
                <Button
                  fullWidthMobile
                  gaTarget={"intro-add-to-cart"}
                  wide
                  onClick={e => {
                    e.preventDefault()

                    addToCart(
                      { id, sku, name, prices: parentPrice, ...product },
                      navigate(product.upsellPath)
                    )
                  }}
                >
                  {modelInCart ? "In cart" : "Add to cart"}
                </Button>
                <div className={style["product-intro__order-multiple"]}>
                  <Tooltip
                    isTooltipOpen={isTooltipOpen}
                    closeTooltip={e => {
                      e.preventDefault()
                      setIsTooltipOpen(false)
                    }}
                  >
                    <p>{"Interested in ordering multiple of this product?"}</p>
                    <div>
                      <Button
                        to={`tel:${config[
                          process.env.GATSBY_LANGUAGE
                        ].phoneNumber.replace(/ /g, "")}`}
                        short
                      >
                        {"Give us a Call"}
                      </Button>
                      <Button
                        to={"/contact-us/get-a-quote/"}
                        state={{
                          heading: name,
                          image: heroImage.gatsbyImageData,
                          excerpt
                        }}
                        short
                      >
                        {"Get a Quote"}
                      </Button>
                    </div>
                  </Tooltip>
                  <Button
                    type={"button"}
                    wide
                    unstyled
                    tertiary
                    center
                    gaTarget={"customise-order-multiple"}
                    onClick={e => {
                      e.preventDefault()
                      setIsTooltipOpen(true)
                    }}
                  >
                    {"Ordering multiple?"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Block>
      </Container>

      <div className={style["product-intro__bottom"]}>
        <Container>
          <ProductAggregateSpecs netoProduct={product.netoProduct} />
          {linksArr.length > 0 && (
            <div className={style["product-intro__secondary-actions"]}>
              {linksArr.map(link => (
                <Button
                  {...link}
                  wide
                  secondary
                  white
                  key={`product-intro-link-${link.id}`}
                />
              ))}
            </div>
          )}
        </Container>
      </div>

      {sectionNav.length > 1 && (
        <div className={style["product-intro__nav-wrapper"]}>
          <nav
            ref={productNav}
            className={[style["product-intro__nav"], ...navModifiers].join(" ")}
          >
            <ul>
              {sectionNav.map((section, i) => (
                <li
                  key={`product-section-link-${section.id}-${i}`}
                  className={
                    has3dViewer ? style[`product-intro__nav-${section.id}`] : ""
                  }
                  onClick={() => {
                    if (has3dViewer && section.text === "3D Viewer") {
                      gtmPush({
                        event: "product-sticky-nav-click",
                        label: "3D Viewer Sticky Nav Clicked"
                      })
                    }
                  }}
                >
                  <Scroll type="id" element={section.id}>
                    <a href={`#${section.id}`}>{section.text}</a>
                  </Scroll>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </div>
  )
}

const ProductAggregateSpecs = ({ netoProduct }) => {
  const { seats, dimensions, jets } = netoProduct
  const specs = []

  seats &&
    specs.push({
      name: `Seating`,
      icon: <IconSeats />,
      label: `From ${seats} adults`
    })

  dimensions &&
    specs.push({
      name: `Dimensions`,
      icon: <IconDimensions />,
      label: `From ${dimensions}`
    })

  jets &&
    specs.push({
      name: `Jets`,
      icon: <IconJets />,
      label: `${jets}`
    })

  if (specs.length < 1) return null

  return (
    <div className={style["product-intro__specs"]}>
      {specs.map(({ name, icon, label }) => (
        <dl key={`spec-${name}`} className={style["product-intro__spec"]}>
          <dt>
            <h3>{name}</h3>
          </dt>
          <dd>
            <span className={style["product-intro__spec-icon"]}>{icon}</span>
            <span className={style["product-intro__spec-label"]}>{label}</span>
          </dd>
        </dl>
      ))}
    </div>
  )
}

const ProductIntroFinance = ({ finance }) => {
  return (
    <div className={style["product-intro__finance"]}>
      {finance.length > 1 ? (
        <>
          <div className={style["product-intro__finance-title"]}>
            {finance.map(item => (
              <GatsbyImage
                image={
                  item.wideIcon
                    ? item.logoWide.gatsbyImageData
                    : item.logo.gatsbyImageData
                }
              />
            ))}
          </div>
          <div className={style["product-intro__finance-disclaimer"]}>
            <ProductFinanceDisclaimer
              displayText={"Finance Options on Select Models*"}
              linkPath={"/contact-us/finance-enquiry/"}
            />
          </div>
        </>
      ) : (
        <>
          <div className={style["product-intro__finance-title"]}>
            <GatsbyImage
              image={
                finance[0].wideIcon
                  ? finance[0].logoWide.gatsbyImageData
                  : finance[0].logo.gatsbyImageData
              }
            />
          </div>
          <div className={style["product-intro__finance-disclaimer"]}>
            <ProductFinanceDisclaimer
              displayText={finance[0].name}
              linkPath={finance[0].enquiryFormPage.path}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ProductIntro

export const query = graphql`
  fragment ProductIntro on DatoCmsProduct {
    name
    id
    slug
    clearliftCompatible
    motorised
    freeDelivery
    newHeroImage: heroImage {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", w: "1122", q: 60, auto: "format" }
      )
      alt
    }

    heroImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {
          h: "485"
          fit: "fill"
          fill: "solid"
          fillColor: "00ffffff"
          w: "820"
          q: 60
          auto: "format"
        }
      )
      alt
    }
    links {
      ...Link
    }
    showImageDisclaimer
    brand {
      ...BrandTag
    }
    netoProductType
    netoProduct {
      ...Price
      ...ProductFinance
      ...ProductAggregateSpecs
    }
  }
  fragment ProductFinance on NetoProduct {
    finance {
      ...CustomiseFinance
    }
  }

  fragment ProductAggregateSpecs on NetoProduct {
    seats
    dimensions
    jets
  }
`
