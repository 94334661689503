import React from "react"
import style from "./ticker.mod.scss"
import { Button } from "@components"
import { useStaticQuery, graphql } from "gatsby"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import {
  filterByLocale,
  filterByCountry,
  promoChecker
} from "@helpers/miscHelpers"

SwiperCore.use([Autoplay])

const Ticker = () => {
  const data = useStaticQuery(graphql`
    query TickerQuery {
      globalConfig: allDatoCmsGlobal {
        nodes {
          locale
          ticker {
            ...Link
            locale
          }
        }
      }

      promotions: allDatoCmsPromotion {
        nodes {
          id
          locale
          country
          tickerText
          startDate
          endDate
          pageLink {
            ... on DatoCmsPage {
              id
              path
            }
            ... on DatoCmsCollection {
              id
              path
            }
          }
        }
      }
    }
  `)

  const [localeGlobal] = filterByLocale(data.globalConfig.nodes)
  const promos = filterByCountry(filterByLocale(data.promotions.nodes))
  const tickerLinks = filterByLocale(localeGlobal.ticker)
  const promoTickers = promos.map(promo => {
    if (promoChecker(promo.startDate, promo.endDate, promo.locale))
      return {
        id: promo.id,
        displayText: promo.tickerText,
        link: promo.pageLink ? { path: promo.pageLink.path } : null
      }
    else return null
  })
  promoTickers && tickerLinks.unshift(...promoTickers.filter(Boolean))

  const config = {
    modules: [Autoplay],
    direction: "vertical",
    autoplay: {
      delay: 6000
    },
    loop: true,
    speed: 400,
    updateOnWindowResize: false
  }

  return (
    <div className={style.ticker}>
      <div className={style.ticker__top}>
        <Button
          gaTarget={"ticker-mobile-get-a-quote-button"}
          children="Get a Quote"
          to="/contact-us/get-a-quote/"
          secondary
          white
          short
          wide
        />
      </div>
      <div className={style.ticker__bottom}>
        <div className={style.ticker__inner}>
          <Swiper {...config}>
            {tickerLinks &&
              tickerLinks.length > 0 &&
              tickerLinks.map(({ id, displayText, link, url }) => {
                return (
                  <SwiperSlide
                    key={`ticker-link-${id}`}
                    className={style.ticker__link}
                  >
                    <Button
                      gaTarget={"ticker-link"}
                      children={config.children}
                      to={link && link.path ? link.path : url}
                      unstyled
                    >
                      <h3>{displayText}</h3>
                      <FaAngleRight className={style["ticker__icon"]} />
                    </Button>
                  </SwiperSlide>
                )
              })}
          </Swiper>
          <div className={style.ticker__cta}>
            <Button
              gaTarget={"ticker-get-a-quote-button"}
              children="Get a Quote"
              to="/contact-us/get-a-quote/"
              secondary
              white
              short
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ticker
