import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Intro, Cards, Card, Block, Container } from "@components"
import Layout from "@layouts/layout"
import ModuleArea from "../helpers/moduleArea"
import SearchBar from "../partials/components/search/search-bar"
import LearnCentreSearchContext from "@context/learnCentreSearchContext"
import { InstantSearch } from "react-instantsearch-dom"
import CategoryCards from "../partials/elements/category-cards"

const Page = props => {
  const { data, location, pageContext } = props
  const {
    title,
    leadNode,
    heroImage,
    bannerImage,
    bannerLink,
    videoPlaceholder,
    heroVideo,
    seoMetaTags,
    content,
    pathways,
    showPathways,
    showHeroImage,
    showLearnCentreSearch,
    categories,
    popularTopics
  } = data.page

  const context = useContext(LearnCentreSearchContext)
  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  )

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: title === "Learn" ? "Learn Centre" : title,
    lead: leadNode.childMarkdownRemark.html,
    heroImage,
    videoPlaceholder,
    heroVideo,
    showHeroImage,
    banner: { image: bannerImage, link: bannerLink }
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters>
        <Intro {...introConfig} />
      </Block>
      {showLearnCentreSearch && (
        <>
          <Container maxWidth={"inner"}>
            <InstantSearch
              searchClient={context.searchClient}
              indexName={context.index.name}
              onSearchStateChange={context.onSearchStateChange}
              createURL={context.createURL}
              searchState={context.searchState}
            >
              <Block gutters padding={`double-bottom`} relative>
                <SearchBar context={{ ...context }} suggested={popularTopics} />
              </Block>
            </InstantSearch>
          </Container>
          <CategoryCards
            categories={categories}
            border={"top"}
            padding={"top_4"}
          />
        </>
      )}
      {content && (
        <Block>
          <ModuleArea modules={content} location={location} />
          <CategoryCards />
        </Block>
      )}
      {showPathways && (
        <Block padding="both" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!, $language: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      title
      id
      slug
      path
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "550"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      showHeroImage
      videoPlaceholder: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "658"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      bannerLink {
        ...Link
      }
      bannerImage {
        url
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "295"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
      }
      heroVideo {
        url
        title
        provider
        providerUid
        thumbnailUrl
        width
        height
      }
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      ...PageContentModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      showDisclaimer
      pathways {
        ...Card
      }
      showPathways
      showTrustPilot
      showLearnCentreSearch
      categories {
        title
        slug
        cardImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "229"
              w: "370"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
        }
        cardIntroText
      }
      popularTopics {
        ... on DatoCmsBlogPost {
          id
          path
          title
        }
        ... on DatoCmsPage {
          id
          path
          title
        }
      }
    }
    tags: allDatoCmsTag(
      filter: { slug: { ne: "specials" }, locale: { eq: $language } }
    ) {
      nodes {
        id
        name
        slug
        position
      }
    }
    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`

export default Page
