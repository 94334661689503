import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Layout from "@layouts/layout"
import {
  Intro,
  Banner,
  Block,
  Container,
  Disclaimer,
  FilteredList
} from "@components"
import JacuzziHero from "../partials/jacuzzi/hero"
import JacuzziFeaturedCard from "../partials/jacuzzi/featured-card"
import { transformProductToCard } from "@helpers/productHelpers"
import { removeDuplicates, configTagsForSelect } from "@helpers/miscHelpers"
import ModuleArea from "../helpers/moduleArea"
import { gtmPush } from "@helpers/gtmHelpers"
import moment from "moment"
import { JsonLd } from "react-schemaorg"

const ProductCollection = ({ location, pageContext, data }) => {
  const {
    name,
    leadNode,
    lead,
    path,
    seoMetaTags,
    products,
    showPriceDisclaimer,
    tag,
    banner,
    datoContent,
    collectionsGrid
  } = data.collection

  const disclaimer = data.globalConfig.priceDisclaimerNode

  const tagType = tag?.__typename || ""

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: name,
    lead: leadNode.childMarkdownRemark.html
  }

  const productTags = ["brand", "price", "usage", "size"]

  const allTagsInCollection = products
    .map(product =>
      productTags.filter(tag => product[tag]).map(tag => product[tag])
    )
    .flat(2)

  const tagsInCollection = removeDuplicates(allTagsInCollection)

  const getTags = tagWanted => {
    if (tagType.toLowerCase().includes(tagWanted)) return []

    return tagsInCollection.filter(({ model }) => model.apiKey === tagWanted)
  }

  const orderTags = array => array.sort((a, b) => a.name.localeCompare(b.name))

  const collectionBrands = orderTags([...getTags("brand")])
  const collectionPrices = orderTags([...getTags("price")])
  const collectionSizes = orderTags([...getTags("size")])
  const collectionUsages = orderTags([...getTags("usage")])

  const filters = []

  collectionBrands.length > 1 &&
    filters.push({
      name: "brand",
      label: "brand",
      placeholder: {
        text: "Select brand"
      },
      options: [...configTagsForSelect(collectionBrands)]
    })

  collectionPrices.length > 1 &&
    filters.push({
      name: "price",
      label: "price",
      placeholder: {
        text: "Select price range"
      },
      options: [...configTagsForSelect(collectionPrices)]
    })

  collectionSizes.length > 1 &&
    filters.push({
      name: "size",
      label: "size",
      placeholder: {
        text: "Select seat number"
      },
      options: [...configTagsForSelect(collectionSizes)]
    })

  collectionUsages.length > 1 &&
    filters.push({
      name: "usage",
      label: "usage",
      placeholder: {
        text: "Select usage"
      },
      options: [...configTagsForSelect(collectionUsages)]
    })

  const configuredProducts =
    products.length > 0 &&
    products
      .map(product => transformProductToCard(product))
      .sort((a, b) => a?.pricing?.price - b?.pricing?.price)

  const isJacuzzi = data.collection.jacuzziFeatureCollection

  const remarketCategories = [
    "Saunas",
    "Pergolas",
    "Umbrellas",
    "Outdoor Fires",
    "Spa Pools",
    "Plunge Pools",
    "Swim Spas",
    "Family Spa",
    "Entertainer"
  ]
  const remarketBrands = ["Jacuzzi", "Fisher", "Vortex"]
  if (remarketCategories.some(el => name.includes(el))) {
    gtmPush({
      event: "remarketing",
      remarketing: {
        product_id: "0",
        brand: "0",
        product_category: name
      }
    })
  } else if (remarketBrands.some(el => name.includes(el))) {
    gtmPush({
      event: "remarketing",
      remarketing: {
        product_id: "0",
        brand: name,
        product_category: "0"
      }
    })
  }

  useEffect(() => {
    const ecommerceItem = []
    if (products.length > 0) {
      products.forEach(item => {
        const isPromoCurrent = moment().isBetween(
          moment(item.netoProduct.promotionStartDate),
          moment(item.netoProduct.promotionExpiryDate)
        )
        ecommerceItem.push({
          item_id: item.netoProduct?.sku,
          item_name: item.name,
          affiliation: "Spa World",
          item_brand: item.brand?.name,
          item_category: pageContext?.treeParent?.title,
          item_variant: "N/A",
          item_list_name: name,
          item_list_id: name?.toLowerCase().replaceAll(" ", "-"),
          price: isPromoCurrent
            ? item.netoProduct?.retailPrice
            : item.netoProduct?.promotionPrice,
          currency: item.netoProduct?.currency,
          quantity: "1"
        })
      })

      gtmPush({ ecommerce: null })
      gtmPush({
        event: "view_item_list",
        ecommerce: {
          item_list_name: name,
          item_list_id: name.toLowerCase().replaceAll(" ", "-"),
          items: ecommerceItem
        }
      })
    }
  }, [products])

  const ecommerceOnClick = item => {
    const ecommerceItem = [
      {
        item_id: item.sku,
        item_name: item.heading,
        affiliation: "Spa World",
        item_brand: item.brand?.name,
        item_category: pageContext?.treeParent?.title,
        item_variant: "N/A",
        item_list_name: name,
        item_list_id: name?.toLowerCase().replaceAll(" ", "-"),
        price: item.pricing?.now,
        currency: item.pricing?.currency,
        quantity: "1"
      }
    ]

    gtmPush({ ecommerce: null })
    gtmPush({
      event: "select_item",
      ecommerce: {
        item_list_name: name,
        item_list_id: name,
        items: ecommerceItem
      }
    })
  }

  const schema = generateSchemaMarkup(products, name, lead, path)

  return (
    <Layout isJacuzzi={isJacuzzi}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />

      {isJacuzzi && (
        <JacuzziHero
          title={name}
          caption={
            data.collection.jacuzziHeroTextNode
              ? data.collection.jacuzziHeroTextNode.childMarkdownRemark
              : ""
          }
          image={data.collection.jacuzziHeroImage}
          cta={data.collection.jacuzziHeroLink}
          breadcrumbs={pageContext.breadcrumbs}
        />
      )}

      {!isJacuzzi && (
        <Block>
          <Intro {...introConfig} banner={banner} />
        </Block>
      )}

      {collectionsGrid?.length > 0 && (
        <Block padding="double-bottom">
          <ModuleArea modules={collectionsGrid} location={location} />
        </Block>
      )}

      <Container>
        <div id="products" name="products" />
        {configuredProducts.length > 0 ? (
          <FilteredList
            items={[...configuredProducts]}
            filters={filters}
            defaultQueries={new Map()}
            location={location}
            onCardClick={ecommerceOnClick}
            itemsVisible={50}
          />
        ) : (
          <center>
            Sorry, there are currently no available products in this collection.
          </center>
        )}
        {isJacuzzi && data.collection.jacuzziBannerText && (
          <Block gutters padding="double-top-single-bottom">
            <JacuzziFeaturedCard
              heading={data.collection.jacuzziBannerHeading}
              caption={data.collection.jacuzziBannerText}
              image={data.collection.jacuzziBannerImage}
              link={data.collection.jacuzziBannerLink}
              id="1"
            />
          </Block>
        )}
      </Container>
      {!!datoContent && (
        <Block padding="double-top-single-bottom">
          <ModuleArea modules={datoContent} location={location} />
        </Block>
      )}
      {showPriceDisclaimer && (
        <Block gutters padding="both">
          <Container maxWidth={"inner"}>
            <Disclaimer content={disclaimer} width={"small"} />
          </Container>
        </Block>
      )}
      <JsonLd item={schema} />
    </Layout>
  )
}

const generateSchemaMarkup = (
  products,
  collectionName,
  collectionDescription,
  collectionUrl
) => {
  const itemListElement = products.map((product, index) => ({
    "@type": "ListItem",
    position: index + 1,
    url: product.path
  }))

  return {
    "@context": "http://schema.org",
    "@type": "CollectionPage",
    name: collectionName,
    description: collectionDescription,
    url: collectionUrl,
    numberOfItems: products.length,
    itemListElement: itemListElement
  }
}

export const query = graphql`
  query collectionById($id: String!, $language: String!) {
    collection: datoCmsCollection(id: { eq: $id }) {
      slug
      name
      path
      id
      showPriceDisclaimer
      lead
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      collectionsGrid {
        ... on DatoCmsCollectionsGrid {
          ...CollectionsGrid
        }
      }
      banner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "175"
              w: "420"
              fit: "cover"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
      products {
        ...ProductCard
      }
      parentCategory {
        id
        slug
        title
      }
      ...ProductCollectionModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      tag {
        ... on DatoCmsBrand {
          id
          name
          slug
          __typename
        }
        ... on DatoCmsUsage {
          id
          name
          slug
          __typename
        }
        ... on DatoCmsSize {
          id
          name
          slug
          __typename
        }
        ... on DatoCmsPrice {
          id
          name
          slug
          __typename
        }
        ... on DatoCmsTag {
          id
          name
          slug
          __typename
        }
      }
      image {
        gatsbyImageData
      }
      jacuzziFeatureCollection
      jacuzziHeroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1600", h: "1080", fit: "crop", q: 70 }
        )
        alt
      }
      jacuzziHeroTextNode {
        childMarkdownRemark {
          html
        }
      }
      jacuzziHeroLink {
        displayText
        url
        locationHash
        link {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsProductLandingPage {
            id
            path
          }
          ... on DatoCmsProduct {
            id
            path
          }
          ... on DatoCmsCollection {
            id
            path
          }
          ... on DatoCmsBlogPost {
            id
            path
          }
        }
      }
      jacuzziBannerHeading
      jacuzziBannerText
      jacuzziBannerLink {
        displayText
        url
        locationHash
        link {
          ... on DatoCmsPage {
            id
            path
          }
          ... on DatoCmsProductLandingPage {
            id
            path
          }
          ... on DatoCmsProduct {
            id
            path
          }
          ... on DatoCmsCollection {
            id
            path
          }
          ... on DatoCmsBlogPost {
            id
            path
          }
        }
      }
      jacuzziBannerImage {
        fluid(
          maxWidth: 1600
          imgixParams: { w: "1600", h: "1080", fit: "crop", q: 70 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      priceDisclaimerNode {
        childMarkdownRemark {
          html
          excerpt(format: HTML)
        }
      }
    }
  }
`

export default ProductCollection
