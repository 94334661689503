import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Intro, Cards, Card, Block, Container } from "@components"
import Layout from "@layouts/layout"
import ModuleArea from "../helpers/moduleArea"
import { JsonLd } from "react-schemaorg"
import PropTypes from "prop-types"
import { gtmPush } from "@helpers/gtmHelpers"
import ThemeContext from "@context/themeContext"

const BlogPost = ({ data, location, pageContext }) => {
  const {
    title,
    leadNode,
    heroImage,
    heroVideo,
    seoMetaTags,
    content,
    pathways,
    showPathways,
    showHeroImage,
    publicationDate,
    meta,
    authoredBy
  } = data.blogPost

  const { setDepth, blogDepth } = useContext(ThemeContext)

  useEffect(() => {
    gtmPush({
      event: "blog-page-view",
      label: "Blog Detail",
      value: title,
      blogDepth: blogDepth + 1
    })

    setDepth(`blogDepth`, blogDepth + 1)
  }, [])

  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  )

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title,
    lead: leadNode.childMarkdownRemark.html,
    heroImage,
    heroVideo,
    showHeroImage
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: title,
    image: [],
    dateModified: meta.updatedAt,
    datePublished: publicationDate
  }

  if (authoredBy.length > 0) {
    schema.author = authoredBy.map(author => ({
      "@type": "Person",
      name: author.author
    }))
  }

  heroImage && heroImage.url && schema.image.push(heroImage.url)

  if (content && authoredBy.length > 0) {
    const authorBlock = {
      author: authoredBy[0].author,
      authoredByText: authoredBy[0].authoredByText,
      model: { apiKey: "authored_by" }
    }

    if (
      content.at(-2).model.apiKey !== "authored_by" &&
      content.at(-1).model.apiKey !== "authored_by"
    ) {
      if (content.at(-1).model.apiKey === "blog_post_grid") {
        const relatedArticles = content.pop()
        content.push(authorBlock, relatedArticles)
      } else {
        content.push(authorBlock)
      }
    }
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters>
        <Container>
          <Intro {...introConfig} />
        </Container>
      </Block>
      {content && (
        <Block>
          <ModuleArea modules={content} location={location} />
        </Block>
      )}
      {showPathways && (
        <Block padding="double" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
      <JsonLd item={schema} />
    </Layout>
  )
}

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  leadNode: PropTypes.object,
  heroImage: PropTypes.shape({
    gatsbyImageData: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired
  }),
  seoMetaTags: PropTypes.object.isRequired,
  content: PropTypes.array,
  pathways: PropTypes.array,
  showPathways: PropTypes.bool,
  showHeroImage: PropTypes.bool,
  publicationDate: PropTypes.string,
  meta: PropTypes.object
}

export default BlogPost

export const query = graphql`
  query blogPostById($id: String!, $language: String!) {
    blogPost: datoCmsBlogPost(id: { eq: $id }) {
      id
      title
      path
      slug
      showHeroImage
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        url
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "550"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      pathways {
        ...Card
      }
      showPathways
      showTrustPilot
      ...BlogContentModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      publicationDate
      meta {
        updatedAt
      }
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`
