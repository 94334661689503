import React from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import ChooserContext from "@context/chooserContext"
import { Intro, Block, Chooser, Grid, ProductCard, Seo } from "@components"
import { motion } from "framer-motion"
import uniqid from "uniqid"
import { transformProductToCard } from "@helpers/productHelpers"

const ProductSelector = ({ data, pageContext, ...props }) => {
  const { chooser, products, globalConfig } = data

  const { contactPage } = globalConfig

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: "Product Selector"
  }

  const customStep = {
    heading: "What are you interested in?",
    id: `DatoCmsSelectorStep-${uniqid()}`,
    answers: [
      {
        id: `DatoCmsAnswer-${uniqid()}`,
        excludeMatches: null,
        displayText: "Spa Pools",
        relatedProducts: null,
        path: "/spa-pools/"
      },
      {
        id: `DatoCmsAnswer-${uniqid()}`,
        excludeMatches: null,
        displayText: "Swim Spas",
        relatedProducts: null,
        path: "/swim-spas/"
      },
      {
        id: `DatoCmsAnswer-${uniqid()}`,
        excludeMatches: null,
        displayText: "Plunge Pools",
        relatedProducts: null,
        path: "/products/plunge-pools/"
      },
      {
        id: `DatoCmsAnswer-${uniqid()}`,
        excludeMatches: null,
        displayText: "Saunas",
        relatedProducts: null,
        path: "/products/saunas/"
      }
      // TODO: Add back in once Pergolas are launched
      // {
      //   id: `DatoCmsAnswer-${uniqid()}`,
      //   excludeMatches: null,
      //   displayText: "Pergolas",
      //   relatedProducts: null,
      //   path: "/products/pergolas/"
      // }
    ]
  }

  const spaPools = products.nodes.filter(
    ({ category }) =>
      category.slug === "spa-pools" || category.slug === "swim-spas"
  )

  const steps = [customStep, ...chooser.nodes]

  return (
    <Layout>
      <Seo title={`Product Selector`} />
      <Intro {...introConfig} />
      <Block type="section" gutters padding="bottom">
        <ChooserContext.Consumer>
          {({
            updateChooser,
            answers,
            currentIndex,
            isComplete,
            recommendations
          }) => {
            return (
              <>
                <Chooser
                  steps={steps}
                  updateChooser={updateChooser}
                  currentIndex={currentIndex}
                  isComplete={isComplete}
                  products={spaPools}
                  contactPagePath={contactPage.path}
                />

                <Grid moduleStyle={"3-up-grid"}>
                  {recommendations &&
                    recommendations
                      .sort((a, b) => a?.netoProduct?.rrp - b?.netoProduct?.rrp)
                      .map(product => {
                        const productCardConfig = transformProductToCard(
                          product
                        )

                        return (
                          <motion.div
                            key={`product-${product.id}`}
                            positionTransition
                          >
                            <ProductCard
                              {...productCardConfig}
                              showRecommendation={false}
                            />
                          </motion.div>
                        )
                      })}
                </Grid>
              </>
            )
          }}
        </ChooserContext.Consumer>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query tagsByLanguage($language: String!) {
    chooser: allDatoCmsSelectorStep(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      nodes {
        ...ChooserSteps
      }
    }

    products: allDatoCmsProduct(
      filter: {
        country: { in: [$language, "Both"] }
        locale: { eq: $language }
      }
    ) {
      nodes {
        tags {
          id
          name
          slug
        }
        category {
          slug
        }
        ...ProductCard
      }
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
    }
  }
`

export default ProductSelector
