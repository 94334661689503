import React, { Component } from "react"
import { MenuItem } from "@components"
import style from "./drilldown.mod.scss"
import { motion } from "framer-motion"
import uniqid from "uniqid"

export default class Drilldown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      curDepth: 0,
      curChildMenu: [],
      curChildHeading: "",
      prevChildMenu: [],
      prevChildHeading: "",
      isNestedMenuVisible: false
    }
  }

  returnToIndex(curDepth = 0, prevChildMenu = [], prevChildHeading = "") {
    curDepth > 1
      ? this.setState({
          curChildMenu: prevChildMenu,
          curChildHeading: prevChildHeading,
          curDepth: 1,
          isNestedMenuVisible: true
        })
      : this.setState({
          curChildMenu: [],
          curChildHeading: "",
          curDepth: 0
        })
  }

  setChildMenu(items, heading = "", curDepth) {
    this.setState({
      curChildMenu: items,
      curChildHeading: heading,
      curDepth,
      isNestedMenuVisible: true
    })
  }

  setPrevChildMenu(curChildMenu, curChildHeading = "") {
    this.setState({
      prevChildMenu: curChildMenu,
      prevChildHeading: curChildHeading,
      isNestedMenuVisible: false
    })
  }

  flattenedMegaMenuItems({ path, title, id, columns }, i, curDepth) {
    const html = [
      <MenuItem
        key={`drilldown__item-${id}-${i}`}
        dataKey={`drilldown__item-${id}-${i}`}
        link={{ path }}
        modifier={"drilldown"}
        displayText={title}
        vertical
        tabIndex={curDepth === 0 ? 0 : -1}
        index={i}
        onClick={() => this.close()}
        isMobile
      />
    ]

    if (title === "Learn") return html

    columns.every(({ heading, collections, tile }, ii) => {
      if (tile) return
      html.push(
        <MenuItem
          key={`drilldown__item-${uniqid()}-ii`}
          dataKey={`drilldown__item-${i}-${ii}`}
          modifier={"drilldown"}
          link={{ path }}
          displayText={heading}
          onClick={() => {
            this.setPrevChildMenu(collections, heading)
            this.setChildMenu(collections, heading, 1)
          }}
          isButton
          unstyled
          showIcon
          vertical
          tabIndex={curDepth === 0 ? 0 : -1}
          index={ii}
          isMobile
        />
      )
    })

    return html
  }

  megaMenuItems(item, i, curDepth) {
    const html = []

    const columnsWithCollections = item.columns.filter(column => !column.tile)

    let customlink

    if (item.link) {
      customlink = item.link.link?.path
    } else {
      customlink = item.path
    }

    html.push(
      <MenuItem
        key={`drilldown__item-${item.id}-${i}`}
        dataKey={`drilldown__item-${item.id}-${i}`}
        modifier={"drilldown"}
        link={{ path: customlink, url: item.link?.url }}
        displayText={item.title}
        onClick={() => {
          columnsWithCollections.every(column =>
            column.collections.length > 0
              ? (column.hasChildren = true)
              : (column.hasChildren = false)
          )

          this.setPrevChildMenu([...columnsWithCollections], item.title)
          this.setChildMenu([...columnsWithCollections], item.title, 1)
        }}
        isButton
        unstyled
        showIcon
        vertical
        tabIndex={curDepth === 0 ? 0 : -1}
        index={i}
        isMobile
      />
    )

    return html
  }

  close() {
    this.props.closeSidebar()
    this.returnToIndex()
  }

  render() {
    const { items, isVisible } = this.props

    const {
      curDepth,
      curChildMenu,
      curChildHeading,
      isNestedMenuVisible
    } = this.state

    const variants = {
      open: {
        display: "block",
        opacity: 1,
        y: 0,
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
      },
      closed: {
        display: "none",
        opacity: 0,
        y: -16,
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
      }
    }

    return (
      <div className={style.drilldown}>
        <motion.ul
          id={`drilldown__list--index`}
          initial={"closed"}
          animate={isVisible && curDepth === 0 ? "open" : "closed"}
          variants={variants}
          className={style.drilldown__list}
        >
          {items.map((item, i) => {
            const {
              id,
              path,
              title,
              treeChildren = [],
              isMegaMenu = false
            } = item

            const hasChildren = treeChildren.length > 0

            if (isMegaMenu && (path === "/products/" || path === "/learn/"))
              return this.flattenedMegaMenuItems(item, i, curDepth)

            if (isMegaMenu && path !== "/products/")
              return this.megaMenuItems(item, i, curDepth)
            else
              return (
                <MenuItem
                  key={`drilldown__item-${id}-${i}`}
                  dataKey={`drilldown__item-${id}-${i}`}
                  modifier={"drilldown"}
                  link={{ path }}
                  onClick={
                    hasChildren
                      ? e => {
                          e.preventDefault()
                          this.setPrevChildMenu([...treeChildren], title)
                          this.setChildMenu([...treeChildren], title, 1)
                        }
                      : () => this.close()
                  }
                  isButton={hasChildren ? true : false}
                  unstyled
                  displayText={title}
                  showIcon={isMegaMenu || hasChildren}
                  vertical
                  tabIndex={curDepth === 0 ? 0 : -1}
                  index={i}
                  isMobile
                />
              )
          })}
        </motion.ul>

        {curChildMenu.length > 0 && (
          <motion.ul
            initial={"closed"}
            animate={
              isVisible && isNestedMenuVisible && curDepth > 0
                ? "open"
                : "closed"
            }
            variants={{
              open: {
                opacity: 1,
                y: 0,
                transition: {
                  staggerChildren: 0.07,
                  delayChildren: 0.5
                }
              },
              closed: {
                opacity: 0,
                y: -16,
                transition: {
                  staggerChildren: 0.05,
                  staggerDirection: -1
                }
              }
            }}
            className={style.drilldown__child}
          >
            {curChildHeading !== "" && (
              <motion.li className={style.drilldown__subheading}>
                <h4>{curChildHeading}</h4>
              </motion.li>
            )}
            {curChildMenu.map(
              (
                {
                  path,
                  slug,
                  title,
                  name,
                  parentCategory,
                  isExternal = false,
                  hasChildren = false,
                  heading,
                  collections,
                  id
                },
                iii
              ) => {
                const safePath =
                  parentCategory !== undefined
                    ? `/${parentCategory.slug}/${slug}/`
                    : path

                return (
                  <MenuItem
                    key={`drilldown__child-item-${id}-${iii}`}
                    modifier={"drilldown"}
                    dataKey={`drilldown__child-item-${id}-${iii}`}
                    link={{ path: safePath }}
                    displayText={title || name || heading}
                    vertical
                    index={iii}
                    tabIndex={curDepth > 1 ? 0 : -1}
                    onClick={
                      hasChildren
                        ? e => {
                            e.preventDefault()
                            this.setState(
                              {
                                isNestedMenuVisible: false
                              },
                              () =>
                                this.setChildMenu([...collections], heading, 2)
                            )
                          }
                        : () => this.close()
                    }
                    isExternal={isExternal}
                    isButton={hasChildren}
                    showIcon={hasChildren}
                    unstyled
                    isMobile
                  />
                )
              }
            )}

            <MenuItem
              modifier={"drilldown"}
              key={`drilldown__child-item-return-${curChildHeading}`}
              dataKey={`drilldown__child-item-return-${curChildHeading}`}
              displayText={"Back"}
              backIcon
              link={{ path: "/" }}
              onClick={e => {
                e.preventDefault()
                const { curDepth, prevChildMenu, prevChildHeading } = this.state

                this.setState(
                  {
                    isNestedMenuVisible: false
                  },
                  () =>
                    this.returnToIndex(
                      curDepth,
                      prevChildMenu,
                      prevChildHeading
                    )
                )
              }}
              isButton
              unstyled
              vertical
              index={100}
              tabIndex={curDepth > 1 ? 0 : -1}
              isMobile
            />
          </motion.ul>
        )}
      </div>
    )
  }
}
