import React from "react"
import style from "./intro.mod.scss"
import {
  Breadcrumbs,
  Lead,
  Image,
  Video,
  Container,
  Superscript,
  Button,
  Banner,
  Block,
  ReviewBadge
} from "@components"

const Intro = ({
  breadcrumbs,
  title,
  lead,
  banner,
  heroImage,
  videoPlaceholder,
  heroVideo,
  showHeroImage = false,
  links = [],
  logo,
  noTitle = false,
  noCrumbs = false,
  rating = null
}) => {
  const modifiers = []
  noTitle && modifiers.push([`sw--intro--no-title`])
  noCrumbs && modifiers.push([`sw--intro--no-crumbs`])

  return (
    <Container>
      <div className={[style.intro, ...modifiers].join(" ")}>
        <div className={style.intro__container}>
          <div className={style.intro__wrapper}>
            {breadcrumbs && (
              <Block gutters>
                <div className={style.intro__breadcrumbs}>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                </div>
              </Block>
            )}
            {logo && (
              <div className={style.intro__logo}>
                <img src={logo.url} alt={logo.alt} />
              </div>
            )}
            {banner?.image && (
              <Block padding={"double-bottom"}>
                <Banner
                  image={banner?.image}
                  alt={banner?.image?.alt}
                  mobileImage={banner.mobileImage}
                  link={banner?.link?.link?.path || banner?.link?.url}
                  elevated
                />
              </Block>
            )}
            <Block gutters>
              {title && (
                <h1
                  className={style.intro__title}
                  children={<Superscript children={title} />}
                />
              )}
              {rating?.avgRating > 1 && <ReviewBadge rating={rating} />}
              {lead && <Lead body={lead} />}
              {links.length > 0 && (
                <div className={style.intro__buttons}>
                  {links.map((link, i) => (
                    <Button
                      children={link.displayText}
                      to={link.path}
                      key={`intro-button-${link.displayText}-${i}`}
                    />
                  ))}
                </div>
              )}
            </Block>
            {showHeroImage || heroVideo ? (
              <div className={style.intro__hero}>
                {heroVideo ? (
                  <Video
                    standAlone
                    moduleStyle="full-width"
                    video={heroVideo}
                    image={videoPlaceholder}
                  />
                ) : (
                  heroImage && <Image image={heroImage} elevated />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Intro
