import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image"
import style from "./banner.mod.scss"
import { Button } from "@components"

const Banner = ({
  alt = "",
  image,
  mobileImage,
  elevated = false,
  modifiers = [],
  link
}) => {
  elevated && modifiers.push(style["banner--elevated"])

  const isGif = image.gatsbyImageData.images.fallback.src.search(/.gif/g) > -1

  const images = withArtDirection(getImage(image), [
    {
      media: "(max-width: 420px)",
      image: getImage(mobileImage ? mobileImage : image)
    }
  ])

  return (
    <div className={[style.banner, ...modifiers].join(" ")}>
      {link ? (
        <Button to={link} unstyled>
          {!isGif ? (
            <GatsbyImage
              className={
                mobileImage ? style.banner__mobile_image : style.banner__image
              }
              image={images}
              loading="eager"
              alt={alt}
            />
          ) : (
            <img src={image.gatsbyImageData.images.fallback.src} alt={alt} />
          )}
        </Button>
      ) : !isGif ? (
        <GatsbyImage
          className={
            mobileImage ? style.banner__mobile_image : style.banner__image
          }
          image={images}
          loading="eager"
          alt={alt}
        />
      ) : (
        <img src={image.gatsbyImageData.images.fallback.src} alt={alt} />
      )}
    </div>
  )
}

export default Banner

export const query = graphql`
  fragment BannerBlock on DatoCmsCollectionBanner {
    id
    __typename
    link {
      ...Link
    }
    image {
      url
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "295", w: "1170", fit: "crop", q: 60, auto: "format" }
      )
      alt
    }
    mobileImage {
      url
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "175", w: "420", fit: "cover", q: 60, auto: "format" }
      )
      alt
    }
  }
`
