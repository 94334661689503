import React, { useState } from "react"
import PropTypes from "prop-types"
import ThemeContext, { isBrowser } from "@context/themeContext"
import { useStaticQuery, graphql, Script } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import {
  Header,
  Ticker,
  Footer,
  Swirl,
  Drilldown,
  PreFooter,
  BackToTop,
  GlobalSearchWrapper,
  HeaderControl
} from "@components"
import Headroom from "react-headroom"
import { menuTreeFromPages } from "@helpers/menuHelpers"
import FocusLock from "react-focus-lock"
import { motion } from "framer-motion"
import { JsonLd } from "react-schemaorg"

const mql = isBrowser() ? window.matchMedia("(min-width: 1024px)") : false

const mqlTablet = isBrowser() ? window.matchMedia("(max-width: 1024px)") : false

const Layout = ({
  minimal = false,
  children,
  showHeader = true,
  pageType = "",
  isJacuzzi = false
}) => {
  const {
    site,
    allSitePage,
    allDatoCmsMegaMenu,
    content
  } = useStaticQuery(graphql`
    {
      content: contentYaml {
        faq
        customerSupport
      }
      site {
        siteMetadata {
          lang
          checkoutURL
          siteUrl
          facebook
          instagram
          twitter
          pinterest
          title
          logo
          description
          phoneNumber
        }
      }
      allSitePage {
        nodes {
          ...MenuHelpersAllPages
        }
      }

      allDatoCmsMegaMenu {
        nodes {
          id
          position
          title
          locale
          setToEnd
          category {
            ... on DatoCmsProductLandingPage {
              id
              slug
              title
              path
            }
            ... on DatoCmsPage {
              id
              slug
              title
              path
            }
          }
          columns {
            ... on DatoCmsMenuLearnColumn {
              __typename
              id
              heading
              linkPath
              placeholderImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  imgixParams: {
                    h: "182"
                    w: "362"
                    fit: "crop"
                    auto: "format"
                  }
                )
                alt
              }
              collections: links {
                ... on DatoCmsLearnSubcategory {
                  id
                  title: name
                  slug
                  parentCategory {
                    slug
                    __typename
                  }
                }
                ... on DatoCmsLearnCategory {
                  id
                  title
                  slug
                }
                ... on DatoCmsPage {
                  id
                  title
                  slug
                  path
                }
              }
              tile {
                ...Tile
              }
            }
            ... on DatoCmsMenuColumn {
              __typename
              id
              heading
              collections {
                ... on DatoCmsCollection {
                  __typename
                  id
                  name
                  slug
                  path
                }
                ... on DatoCmsCustomCollection {
                  __typename
                  id
                  name
                  link {
                    ...Link
                  }
                }
              }
              tile {
                ...Tile
              }
            }
          }
        }
      }

      allDatoCmsGlobal {
        nodes {
          id
          contactPage {
            id
            locale
          }
        }
      }
    }
  `)

  const [isHeaderPinned, setIsHeaderPinned] = useState(false)

  const menuItems = menuTreeFromPages(
    allSitePage.nodes,
    content,
    allDatoCmsMegaMenu.nodes
  )

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(0px at calc(100% - 32px) 48px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  }

  const sidebarWrapper = {
    open: {
      display: "block"
    },
    closed: {
      display: "none",
      transition: {
        delay: 1.5
      }
    }
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    telephone: site.siteMetadata.phoneNumber,
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: site.siteMetadata.phoneNumber,
        contactType: "customer service"
      }
    ],
    url: site.siteMetadata.siteUrl,
    logo: site.siteMetadata.logo,
    sameAs: [
      site.siteMetadata.facebook,
      site.siteMetadata.instagram,
      site.siteMetadata.twitter,
      site.siteMetadata.pinterest
    ]
  }

  return (
    <>
      <HelmetDatoCms>
        <meta name="format-detection" content="telephone=no" />
        <html lang={site.siteMetadata.lang} />
      </HelmetDatoCms>
      <ThemeContext.Consumer>
        {({
          isSidebarOpen,
          closeSidebar,
          toggleSidebar,
          isCartOpen,
          isSearchOpen,
          setSearchOpen,
          isCartPopup
        }) => {
          minimal && isSidebarOpen && closeSidebar()
          mql && mql.addListener(({ matches }) => matches && closeSidebar())
          const classes = ["layout"]

          minimal && classes.push("layout--minimal")
          classes.push(`layout--sidebar-${isSidebarOpen ? "open" : "close"}`)
          classes.push(`layout--cart-${isCartOpen ? "open" : "close"}`)
          classes.push(`layout--cart-${isCartPopup ? "open" : "close"}`)
          classes.push(`layout--search-${isSearchOpen ? "open" : "close"}`)
          isHeaderPinned && classes.push(`layout--headroom-pinned`)

          return (
            <div id="layout" className={classes.join(" ")}>
              {showHeader && (
                <GlobalSearchWrapper>
                  <FocusLock group="sidebar" disabled={!isSidebarOpen}>
                    <Headroom
                      disableInlineStyles
                      className={
                        isSidebarOpen ? "headroom-wrapper--sidebar" : ""
                      }
                      onPin={() => {
                        setIsHeaderPinned(true)
                      }}
                      onUnpin={() => {
                        setIsHeaderPinned(false)
                      }}
                    >
                      <HeaderControl
                        minimal={minimal}
                        toggleSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}
                        isSearchOpen={isSearchOpen}
                        setSearchOpen={setSearchOpen}
                        isBrowser={isBrowser}
                      />
                      <Header
                        minimal={minimal}
                        menuItems={menuItems}
                        toggleSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}
                        isSearchOpen={isSearchOpen}
                        setSearchOpen={setSearchOpen}
                        isBrowser={isBrowser}
                      />
                      {!minimal && mqlTablet.matches && (
                        <BackToTop element={"#___gatsby"} type="id" />
                      )}
                    </Headroom>
                  </FocusLock>
                  {!minimal && <Ticker />}
                </GlobalSearchWrapper>
              )}

              {!isJacuzzi && <Swirl blob={pageType === "404"} />}

              <div className={"layout__content"}>
                <main>{children}</main>
              </div>

              {!minimal && <PreFooter isJacuzzi={isJacuzzi} />}
              <Footer minimal={minimal} />
              <motion.div
                className={"layout__sidebar-bg-wrapper"}
                initial="closed"
                variants={sidebarWrapper}
                animate={isSidebarOpen ? "open" : "closed"}
              >
                <motion.div
                  dragPropagation
                  animate={isSidebarOpen ? "open" : "closed"}
                  className={"layout__sidebar-bg"}
                  variants={sidebar}
                />
              </motion.div>
              <motion.div
                className={"layout__sidebar"}
                initial={false}
                animate={isSidebarOpen ? "open" : "closed"}
                variants={{
                  open: {
                    display: "block"
                  },
                  closed: {
                    transition: {
                      delay: 1
                    },
                    display: "none"
                  }
                }}
              >
                <motion.div className={"layout__sidebar-scroll"}>
                  <FocusLock group="sidebar" disabled={!isSidebarOpen}>
                    <Drilldown
                      isVisible={isSidebarOpen}
                      items={menuItems}
                      closeSidebar={closeSidebar}
                    />
                  </FocusLock>
                </motion.div>
              </motion.div>
            </div>
          )
        }}
      </ThemeContext.Consumer>
      <JsonLd item={schema} />
      <Script
        src="https://cdn.customgpt.ai/js/embed.js"
        onLoad={() => {
          console.log("Script loaded successfully")
        }}
        strategy="post-hydrate"
        div_id="customgpt_chat"
        p_id="18862"
        P_key="f5c159061c86a0b748b8d1332fdbbb16"
        data-width="100%"
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
