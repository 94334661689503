import React, { useContext, useEffect, useState } from "react"
import { navigate, graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { gtmPush } from "@helpers/gtmHelpers"
import moment from "moment"
import { useLocation } from "@reach/router"
import {
  Customise,
  Block,
  ProductIntro,
  Container,
  Avante,
  Video,
  Specs,
  Compare,
  Carousel,
  JetLayout,
  ShowMore,
  Cards,
  Card,
  Button,
  Disclaimer,
  Swatches,
  Viewer
} from "@components"
import Layout from "@layouts/layout"
import ProductContext, { getPricing } from "@context/productContext"
import ThemeContext from "@context/themeContext"
import CartContext from "@context/cartContext"
import ModuleArea from "@helpers/moduleArea"
import { avanteSpecific } from "@helpers/avanteHelpers"
import { JsonLd } from "react-schemaorg"
import { getJetCarouselItems, gtmCategory } from "@helpers/productHelpers"
import { getOptionsMap } from "@helpers/variants"
import useAddToCart from "@helpers/useAddToCart"
import { Banner } from "../partials/components"
import NewProductIntro from "../partials/components/new-product-intro"

const Product = ({ data, location, pageContext }) => {
  const {
    product: {
      seoMetaTags,
      name,
      brand,
      netoProduct,
      netoProductType,
      productSpecificsComparison,
      productFeatures,
      productDescription,
      productWarranties,
      specTableRows,
      specDescription,
      spaImage,
      swimspaImage,
      productComparison,
      jetLayouts,
      jetLayoutsCarousel,
      jetLayoutDescriptionNode,
      category,
      isWide,
      pathways,
      showPathways,
      showPriceDisclaimer,
      sku,
      colourOneHeading = "Shell Colour",
      colourOneOptions,
      colourTwoHeading = "Cabinet Colour",
      colourTwoOptions,
      colourDisclaimerNode,
      showColourDisclaimer,
      renders,
      viewer = false,
      netoVariants,
      usage,
      productBanner
    },
    content: { avanteContent }
  } = data
  const {
    ready,
    productLoading,
    setProduct,
    resetProduct,
    setSku
  } = useContext(ProductContext)

  const locationRouter = useLocation()
  const params = new URLSearchParams(locationRouter.search)
  const newVersion = params.get("v") === "2" ? true : false

  const { cartLoading, cart, getSubtotal } = useContext(CartContext)
  const { setDepth, productDepth } = useContext(ThemeContext)

  const inCart = !cartLoading && cart.has(sku)

  const { hasUpsells, upsellPath } = pageContext
  const compareProducts = productComparison
  const showSpecs = specTableRows.length > 0 || spaImage || swimspaImage
  const defaultVariant = data.defaultVariant || netoVariants[0] || netoProduct

  useEffect(() => {
    const isPromoCurrent = moment().isBetween(
      moment(netoProduct.promotionStartDate),
      moment(netoProduct.promotionExpiryDate)
    )
    const ecommerceItem = {
      item_id: sku,
      item_name: name,
      affiliation: "Spa World",
      item_brand: brand.name,
      item_category: category.title,
      item_variant: defaultVariant.name,
      price: isPromoCurrent
        ? netoProduct.retailPrice
        : netoProduct.promotionPrice,
      currency: netoProduct.currency,
      quantity: "1"
    }

    gtmPush({
      event: "product-page-view",
      label: "Product Detail",
      value: data.product.name,
      productDepth: productDepth + 1,
      productSku: data.product.sku
    })
    gtmPush({ ecommerce: null })
    gtmPush({
      event: "view_item",
      ecommerce: {
        currency: ecommerceItem.currency,
        value: ecommerceItem.price,
        items: [ecommerceItem]
      }
    })
    setDepth(`productDepth`, productDepth + 1)

    // reset the product context to it's initial state when the product unmounts
    return () => {
      resetProduct()
    }
  }, [])

  if (!netoProduct) {
    console.error(
      `Product ${data.product.name} is missing neto product data. Check sku in DatoCMS`
    )
  }

  const parentPrice = getPricing(data.product.netoProduct)
  data.product.productOptions.forEach(({ options }) => options.sort())

  const options = getOptionsMap(data.product.productOptions)
  const swatches = []

  if (options.has(colourOneHeading) && colourOneOptions.length)
    swatches.push({
      id: "colourOne",
      heading: colourOneHeading,
      swatches: [...colourOneOptions].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    })

  if (options.has(colourTwoHeading) && colourTwoOptions.length)
    swatches.push({
      id: "colourTwo",
      heading: colourTwoHeading,
      swatches: [...colourTwoOptions].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    })

  const productData = {
    ...data.product,
    options,
    parentPrice,
    upsellPath,
    hasUpsells,
    defaultVariant,
    swatches,
    finance: netoProduct.finance
  }

  // while product loading is true, and if the application has mounted, set the product
  useEffect(() => {
    productLoading && ready && setProduct(productData, defaultVariant.sku)
  }, [ready, productLoading])

  // when the product is done loading, and if the parent product can be found in the cart,
  // then get the variant sku from the cart product and set that as the sku
  useEffect(() => {
    if (!productLoading && inCart) {
      const cartProduct = cart.get(sku)
      const currentSku = cartProduct && cartProduct.variantSku

      currentSku && setSku(currentSku)
    }
  }, [productLoading, inCart])

  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  )

  const sectionNav = []

  productData.netoProductType !== "simple" &&
    sectionNav.push({
      text: "Price",
      id: `customise`
    })

  productFeatures &&
    productFeatures.length > 0 &&
    sectionNav.push({
      text: "Features",
      id: `features`
    })

  if (swatches.length) {
    if (viewer) {
      sectionNav.push({
        text: "3D Viewer",
        id: `swatches`
      })
    } else
      sectionNav.push({
        text: "Colours",
        id: `swatches`
      })
  }

  showSpecs &&
    sectionNav.push({
      text: "Specifications",
      id: `specs`
    })

  productWarranties &&
    productWarranties.length > 0 &&
    sectionNav.push({
      text: "Warranties",
      id: `warranties`
    })

  const {
    avanteData,
    avanteThumb,
    avanteUpgradeVideo,
    avanteImage
  } = data.product

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: productData.name,
    brand: productData.brand.name,
    productID: productData.sku,
    image: productData.schemaImage.gatsbyImageData.images.fallback.src,
    description: productData.excerpt,
    sku: productData.sku,
    mpn: productData.sku.replace(/^Z/, ""),
    url: location.href
  }

  schema.offers =
    netoProductType === "configurable"
      ? {
          "@type": "AggregateOffer",
          url: location.href,
          priceCurrency: parentPrice.currency,
          price: parentPrice.price,
          lowPrice: parentPrice.price,
          itemCondition: "https://schema.org/NewCondition",
          availability: "https://schema.org/InStock"
        }
      : {
          "@type": "Offer",
          url: location.href,
          priceCurrency: parentPrice.currency,
          price: parentPrice.price,
          itemCondition: "https://schema.org/NewCondition",
          condition: "new",
          availability: "https://schema.org/InStock"
        }

  const vowels = ["A", "E", "I", "O", "U"]

  let productDescriptionHeading = `Why Buy a ${productData.name}?`

  vowels.forEach(vowel => {
    if (productData.name.startsWith(vowel)) {
      productDescriptionHeading = `Why Buy an ${productData.name}?`
    }
  })

  useEffect(() => {
    gtmPush({
      event: "remarketing",
      remarketing: {
        product_id: productData.sku || "0",
        brand: productData.brand?.name || "0",
        product_category: gtmCategory(usage)
      }
    })
  }, [])

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block type={"section"} id={"intro"}>
        {newVersion ? (
          <NewProductIntro
            {...productData}
            disclaimer={`Image may show optional extras.`}
            sectionNav={sectionNav}
            breadcrumbs={pageContext.breadcrumbs}
            isWide={isWide}
            virtualConsultation={data.content.virtualConsultation}
            has3dViewer={viewer}
          />
        ) : (
          <ProductIntro
            {...productData}
            disclaimer={`Image may show optional extras.`}
            sectionNav={sectionNav}
            breadcrumbs={pageContext.breadcrumbs}
            isWide={isWide}
            virtualConsultation={data.content.virtualConsultation}
            has3dViewer={viewer}
          />
        )}
        <JsonLd item={schema} />
      </Block>

      {productDescription && productDescription.length > 0 && (
        <>
          {productBanner?.length > 0 && (
            <Block padding="double" maxWidth="content" gutters>
              <Banner
                image={productBanner[0]?.image}
                alt={productBanner[0]?.image?.alt}
                mobileImage={productBanner[0]?.mobileImage}
                link={
                  productBanner[0]?.link?.link?.path ||
                  productBanner[0]?.link?.url
                }
                elevated
              />
            </Block>
          )}
          <Block
            type={"section"}
            heading={productDescriptionHeading}
            tight
            padding="double-top"
          >
            <ModuleArea
              modules={productDescription}
              location={location}
              productName={productData.name}
            />
          </Block>
        </>
      )}

      {productFeatures && productFeatures.length > 0 && (
        <Block
          type={"section"}
          id={"features"}
          heading={`${productData.name} Features`}
          padding="double"
          tight
        >
          <Block padding="top">
            <ModuleArea modules={productFeatures} location={location} />
          </Block>
        </Block>
      )}

      {swatches.length > 0 && (
        <Block
          type={"section"}
          id={"swatches"}
          background={"swirl"}
          padding={"double-mobile"}
          overflow={"hidden"}
        >
          {viewer ? (
            <Viewer
              heading={data.content.viewer.heading}
              lead={data.content.viewer.lead}
              name={productData.name}
              placeholder={{
                id: productData.id,
                image: productData.heroImage
              }}
              isWide={isWide}
              itemsLength={colourOneOptions.length + colourTwoOptions.length}
              items={swatches}
              defaultVariant={defaultVariant}
              renders={renders}
              video={data.globalConfig.productArViewerHowToVideo}
              productSpecificsComparison={productSpecificsComparison}
            />
          ) : (
            <Swatches
              heading={data.content.swatches.heading}
              lead={data.content.swatches.lead}
              placeholder={{
                id: productData.id,
                image: productData.heroImage
              }}
              isWide={isWide}
              itemsLength={colourOneOptions.length + colourTwoOptions.length}
              items={swatches}
              defaultVariant={defaultVariant}
              renders={renders}
              colourDisclaimerNode={colourDisclaimerNode}
              showColourDisclaimer={showColourDisclaimer}
            />
          )}
        </Block>
      )}

      {productSpecificsComparison && productSpecificsComparison.length > 0 && (
        <Block padding="double">
          {
            // Product Configuration
            // -----------------------
            // note: section IDs should match the slugs set by knownSpecifics in
            // the variants helper to ensure sections are linked correctly
            //
            // 1. Use productContext to retrieve options and current values.
            // 2. Set value using setOption in productContext
          }

          <ModuleArea
            modules={productSpecificsComparison}
            defaultVariant={defaultVariant}
            location={location}
          />
        </Block>
      )}

      {options.has(avanteSpecific.name) && (
        <Block
          background={"teal"}
          type={"section"}
          id={avanteSpecific.slug}
          padding={"triple-top-single-bottom"}
          heading={avanteContent.heading}
          lead={`<p>Upgrade to the ${productData.name} Avanté and take your spa experience to the next level with a range of features designed for added comfort and superior luxury. The Avanté model brings together a collection of Vortex™ Spas’ most popular upgrades which, when purchased together, offers significant savings.</p>`}
          padIntro
        >
          {avanteUpgradeVideo && avanteImage && (
            <Container>
              <Block padding={"double-bottom"} gutters>
                <Video
                  moduleStyle="Full Width"
                  image={avanteImage}
                  video={avanteUpgradeVideo}
                />
              </Block>
            </Container>
          )}
          {avanteData && avanteData.upgrades && avanteData.upgrades.length > 0 && (
            <Container>
              <Avante
                productImage={avanteThumb}
                features={avanteData && avanteData.upgrades}
                {...productData}
              />
            </Container>
          )}
        </Block>
      )}

      {netoProductType === "configurable" && (
        <Block
          type={"section"}
          id={"customise"}
          gutters
          padding={"double"}
          background={"teal"}
        >
          <Container>
            <Block padding="bottom">
              <Customise
                {...productData}
                heading={`Build your ${productData.name || "Spa"}`}
              />
            </Block>
          </Container>
        </Block>
      )}

      {showSpecs && (
        <Block
          type={"section"}
          id={"specs"}
          padding={"double-top-mobile-single-bottom"}
          heading={"Specifications"}
          lead={
            specDescription.childMarkdownRemark &&
            specDescription.childMarkdownRemark.html
          }
          padIntro
        >
          <Specs
            {...productData}
            isWide={isWide}
            disclaimer={"Image may show optional extras."}
          />
        </Block>
      )}

      {jetLayouts.length > 0 && (
        <Container>
          <Block
            type={"section"}
            padding={"double"}
            gutters
            id="jets"
            heading={"Jet Layout"}
            lead={jetLayoutDescriptionNode.childMarkdownRemark.html}
          >
            <ShowMore
              enabled={jetLayouts.length > 1}
              maxHeight={450}
              contentId={"#jet-wrappers"}
            >
              <div id="jet-wrappers">
                {jetLayouts.map(jetLayout => {
                  return (
                    <JetLayout
                      key={`jet-layout-${jetLayout.id}`}
                      {...jetLayout}
                      isWide={isWide}
                    />
                  )
                })}
              </div>
            </ShowMore>
          </Block>
        </Container>
      )}

      {jetLayoutsCarousel.length > 0 && (
        <Block type="section" padding="double-bottom">
          <Carousel
            items={getJetCarouselItems(jetLayoutsCarousel)}
            moduleStyle="simple"
            padding="bottom"
          />
        </Block>
      )}

      {productWarranties && productWarranties.length > 0 && (
        <Block type={"section"} id={"warranties"}>
          <ModuleArea
            modules={productWarranties}
            location={location}
            productBrand={productData.brand && productData.brand.name}
          />
        </Block>
      )}

      {productComparison && productComparison.length > 0 && (
        <Block
          type={"section"}
          heading={`Similar ${category.title}`}
          lead={
            data.globalConfig.productComparisonDescriptionNode
              .childMarkdownRemark.html
          }
          padding="double-top"
          padIntro
        >
          <Container>
            <Compare compareProducts={compareProducts} product={productData} />
          </Container>
        </Block>
      )}
      {showPathways && (
        <Block padding="double" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
      {showPriceDisclaimer && (
        <Block gutters padding="double-top-single-bottom">
          <Container>
            <Disclaimer
              content={data.globalConfig.priceDisclaimerNode}
              width={"small"}
            />
          </Container>
        </Block>
      )}
    </Layout>
  )
}

const ProductFinanceDisclaimer = ({ displayText, linkPath }) => {
  const { currentModel } = useContext(ProductContext)
  const { addToCart } = useAddToCart()

  return (
    <Button
      unstyled
      onClick={e => {
        e.preventDefault()
        addToCart(currentModel, navigate(linkPath))
      }}
    >
      {displayText}
    </Button>
  )
}

export const query = graphql`
  query productById($id: String!, $language: String!, $sku: String!) {
    product: datoCmsProduct(id: { eq: $id }) {
      sku
      isWide
      viewer
      path
      usage {
        name
        slug
        id
        model {
          apiKey
        }
        __typename
      }
      productBanner {
        id
        __typename
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "175"
              w: "420"
              fit: "cover"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
      schemaImage: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "820"
            w: "820"
            fit: "fill"
            fill: "solid"
            fillColor: "00ffffff"
            crop: "entropy"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      category {
        id
        slug
        title
      }

      colourOneOptions {
        ...SwatchShell
      }

      colourTwoOptions {
        ...SwatchCabinet
      }

      colourDisclaimerNode {
        childMarkdownRemark {
          html
        }
      }
      showColourDisclaimer

      renders {
        ...SwatchesRender
      }

      productOptions
      excerpt
      showPriceDisclaimer
      netoProductType
      netoVariants {
        id
        specifics {
          name
          value
        }
        arProductId
        arVariationId
        viewerCabinetProductId
        viewerCabinetVariationId
        viewerSceneId
        viewerShellProductId
        viewerShellVariationId
        fromDeliveryPrice
        upsells
      }

      netoProduct {
        id
        dimensions
        weightFull
        volume
        seats
        name
        jets
        finance {
          ...FinanceCta
        }
      }

      ...ProductIntro
      ...AvanteTable
      ...Customise
      ...Upsell
      ...Specs
      productComparison {
        ...Compare
      }
      colourOneHeading
      colourTwoHeading
      jetLayoutsCarousel: jetLayouts {
        ...JetListCarousel
      }
      jetLayoutDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      jetLayouts {
        ...JetLayout
      }
      showPathways
      pathways {
        ...Card
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...ProductSpecificsModuleArea
      ...ProductDescriptionModuleArea
      ...ProductFeaturesModuleArea
      ...ProductWarrantiesModuleArea
    }

    defaultVariant: netoProduct(
      parentSku: { eq: $sku }
      defaultModel: { eq: true }
    ) {
      ...CustomiseDefaultVariant
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
      productArViewerHowToVideo {
        url
        title
        provider
        providerUid
        thumbnailUrl
        width
        height
      }
      productComparisonDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      priceDisclaimerNode {
        childMarkdownRemark {
          html
          excerpt(format: HTML)
        }
      }
    }

    content: contentYaml {
      ...AvanteContent

      virtualConsultation

      swatches {
        heading
        lead
      }
      viewer {
        heading
        lead
      }
    }
  }
`

export default Product

export { ProductFinanceDisclaimer }
