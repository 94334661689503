import React, { useContext, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Intro, Block, Container, Modal } from "@components"
import SearchBar from "../partials/components/search/search-bar"
import { Button } from "@components"
import SearchResultsGrid from "../partials/components/search/search-results-grid"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LearnCentreSearchContext from "@context/learnCentreSearchContext"
import { InstantSearch } from "react-instantsearch-dom"
import LearnCentreNavigation from "../partials/elements/learn-centre-navigation"
import { FaRedo } from "@react-icons/all-files/fa/FaRedo"

const LearnCentreResultsPage = ({ data, pageContext }) => {
  const context = useContext(LearnCentreSearchContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoInModal, setVideoInModal] = useState(null)

  const { categories, pageContent } = data
  const content = pageContent.nodes[0]

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: `Search Results`,
    lead: content.lead,
    showHeroImage: false
  }

  const toggleIsModalOpen = boolean => setIsModalOpen(boolean)

  const initVideo = video => {
    // set video to use in modal
    setVideoInModal(video)

    //  set modal open
    toggleIsModalOpen(true)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = {}
      const queryString = window.location.search.slice(1)
      const keyValue = queryString.split("=")
      const key = decodeURIComponent(keyValue[0])
      const value = decodeURIComponent(keyValue[1]) || ""
      urlParams[key] = value

      if (urlParams && urlParams.query) {
        context.onSearchStateChange({ query: urlParams.query })
      }
    }
  }, [])

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={content.seo} />
      <InstantSearch
        searchClient={context.searchClient}
        indexName={context.index.name}
        onSearchStateChange={context.onSearchStateChange}
        createURL={context.createURL}
        searchState={context.searchState}
        refresh={true}
      >
        <Block gutters>
          <Intro {...introConfig} />
        </Block>
        <Container maxWidth={"inner"}>
          <Block gutters padding={`double-bottom`}>
            <SearchBar
              context={{ ...context }}
              suggested={content.popularTopics}
            />
          </Block>
        </Container>
        <LearnCentreNavigation
          breadcrumbs={introConfig.breadcrumbs}
          hasResults
          categories={categories}
        />
        <Container>
          <SearchResultsGrid context={{ ...context }} onClick={initVideo} />
        </Container>
      </InstantSearch>
      <Modal
        onChange={toggleIsModalOpen}
        globalState={isModalOpen}
        video={videoInModal}
        ariaLabel={`Open modal and play video`}
        gtm={{
          event: `video-engagement`,
          label: `Video Model Click`,
          value: videoInModal?.title
        }}
      />
    </Layout>
  )
}

export default LearnCentreResultsPage

export const query = graphql`
  query learnCentreItemsByLanguage($language: String!) {
    videoLibraryItems: allDatoCmsVideoLibraryItem(
      filter: {
        country: { in: [$language, "Both"] }
        locale: { eq: $language }
      }
      sort: { fields: [position], order: DESC }
    ) {
      nodes {
        id
        heading
        caption
        meta {
          createdAt
        }
        overlayImage {
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "207"
              fit: "crop"
              w: "368"
              q: 60
              auto: "format"
            }
          )
        }
        tags {
          name
          slug
          position
        }
      }
    }

    blogPosts: allDatoCmsBlogPost(
      filter: {
        country: { in: [$language, "Both"] }
        locale: { eq: $language }
      }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      nodes {
        id
        title
        path
        leadNode {
          childMarkdownRemark {
            html
          }
        }
        publicationDate(formatString: "DD-MM-YYYY")
        excerptNode {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "265", fit: "crop", w: "500", auto: "format" }
          )
        }
        tags {
          name
          slug
          position
        }
      }
    }
    categories: allDatoCmsLearnSubcategory(
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        id
        name
        slug
        position
      }
    }
    tags: allDatoCmsTag(
      filter: { slug: { ne: "specials" }, locale: { eq: $language } }
    ) {
      nodes {
        id
        name
        slug
        position
      }
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
    }

    pageContent: allDatoCmsPage(
      filter: { slug: { eq: "learn" }, locale: { eq: $language } }
    ) {
      nodes {
        slug
        title
        lead
        seo: seoMetaTags {
          tags
        }
        popularTopics {
          ... on DatoCmsBlogPost {
            id
            path
            title
          }
          ... on DatoCmsPage {
            id
            path
            title
          }
        }
      }
    }
  }
`
