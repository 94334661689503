import React, { useState, useEffect, Fragment } from "react"
import {
  Block,
  Card,
  ProductCard,
  Grid,
  Filter,
  Button,
  GalleryItem
} from "@components"
import PropTypes from "prop-types"

const FilteredList = ({
  items = [],
  filters = [],
  defaultQueries = new Map(),
  type = "product",
  onChange = () => {},
  showFilters = true,
  location,
  onCardClick,
  itemsVisible = 18
}) => {
  const [queries, setQueries] = useState(defaultQueries)
  const [filteredItems, setFilteredItems] = useState([...items])
  const [visibleItems, setVisibleItems] = useState(itemsVisible)

  useEffect(() => {
    console.log("useEffect triggered", { queries, items })
    filterContent()
  }, [queries, items])

  const handleLoadMore = e => {
    e.preventDefault()
    console.log("Load more items")
    setVisibleItems(prevVisibleItems => prevVisibleItems + itemsVisible)
  }

  const filterItems = allItems => {
    return allItems.filter(item => {
      if (queries.size < 1) return true

      const filterFields = [...queries.keys()]

      for (let i = 0; i < filterFields.length; i++) {
        const type = filterFields[i]
        const itemValue = item[type]

        if (!queries.has(type) || queries.get(type) === "") {
          continue
        }

        if (!itemValue) return false

        const value = queries.get(type)

        if (Array.isArray(itemValue)) {
          if (itemValue.find(({ slug }) => slug === value) === undefined)
            return false
        } else if (itemValue.slug && itemValue.slug !== value) {
          return false
        } else if (!itemValue.slug && itemValue !== value) {
          return false
        }
      }
      return true
    })
  }

  const updateQueries = ({ target }) => {
    const newQuery = new Map(queries)
    newQuery.set(target.name, target.value)
    console.log("Updating queries", newQuery)
    setQueries(newQuery)
  }

  const filterContent = () => {
    const filteredList = filterItems(items)
    console.log("Filtering content", filteredList)
    setFilteredItems(filteredList)
    onChange(filteredList)
  }

  const clearFilter = () => {
    console.log("Clearing filter")
    setQueries(new Map())
    onChange(items)
    setFilteredItems(items)
  }

  const renderType = item => {
    switch (type) {
      case "gallery":
        return <GalleryItem {...item} items={filteredItems} />
      case "blog":
        return <Card {...item} truncate={3} />
      default:
        return <ProductCard {...item} onClick={() => onCardClick(item)} />
    }
  }

  const renderDisplayText = () => {
    switch (type) {
      case "gallery":
        return "Images"
      case "blog":
        return "Articles"
      default:
        return "Products"
    }
  }

  let gridStyle = "3-up-grid"
  if (type === "blog") gridStyle = "2-up-grid"
  if (type === "gallery") gridStyle = "3-up-masonary-grid"

  const initialValues = Object.fromEntries(queries)

  return (
    <>
      {filters.length > 0 && showFilters && (
        <Block gutters>
          <form
            onSubmit={e => {
              e.preventDefault()
              filterContent(e)
            }}
          >
            <Filter
              onClick={clearFilter}
              onChange={updateQueries}
              filters={filters.map(filter => ({
                ...filter,
                value: initialValues[filter.name] || ""
              }))}
              displayText={renderDisplayText()}
            />
          </form>
        </Block>
      )}
      {filteredItems.length > 0 ? (
        <Block
          padding={filters.length > 0 && showFilters ? "double-top" : "None"}
        >
          <Grid moduleStyle={gridStyle}>
            {filteredItems.slice(0, visibleItems).map(item => {
              if (!item.hidden) {
                return (
                  <Fragment key={`card--${item.id}`}>
                    {renderType(item)}
                  </Fragment>
                )
              }
            })}
          </Grid>
        </Block>
      ) : (
        <Block padding="double" gutters>
          <center>
            Sorry, we can't find an exact match for your search. Please try
            again.
          </center>
        </Block>
      )}
      {filteredItems.length > visibleItems && (
        <Block gutters padding="double-top-single-bottom">
          <Button
            type="button"
            onClick={handleLoadMore}
            children="Load More"
            fullWidthMobile
            block
            short
          />
        </Block>
      )}
    </>
  )
}

export default FilteredList

FilteredList.propTypes = {
  items: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  defaultQueries: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  showFilters: PropTypes.bool,
  location: PropTypes.object,
  onCardClick: PropTypes.func,
  itemsVisible: PropTypes.number
}
