import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Layout from "@layouts/layout"
import { Intro, Block, Disclaimer, Container } from "@components"
import ModuleArea from "../helpers/moduleArea"
import { gtmPush } from "@helpers/gtmHelpers"

const ProductLanding = props => {
  const { data, location, pageContext } = props
  const { title, leadNode, seoMetaTags, content } = data.landing

  const disclaimer = data.globalConfig.priceDisclaimerNode

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title,
    lead: leadNode.childMarkdownRemark.html
  }

  if (title !== "Products") {
    gtmPush({
      event: "remarketing",
      remarketing: {
        product_id: "0",
        brand: "0",
        product_category: title
      }
    })
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters>
        <Intro {...introConfig} />
      </Block>
      {content && (
        <Block padding="bottom">
          <ModuleArea modules={content} location={location} />
        </Block>
      )}
      <Block gutters>
        <Container>
          <Disclaimer content={disclaimer} width={"small"} />
        </Container>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query productLandingById($id: String!, $language: String!) {
    landing: datoCmsProductLandingPage(id: { eq: $id }) {
      id
      title
      slug
      showPriceDisclaimer
      path
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1600", fit: "crop", q: 60, auto: "format" }
        )
        alt
      }
      ...ProductLandingModuleArea
    }
    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      priceDisclaimerNode {
        id
        childMarkdownRemark {
          html
          excerpt(format: HTML)
        }
      }
    }
  }
`

export default ProductLanding
