import React from "react"
import Layout from "@layouts/layout"
import queryString from "query-string"
import ModuleArea from "../helpers/moduleArea"
import { graphql } from "gatsby"
import { Intro, Block, Container, Cards, Card } from "@components"

const PostPurchase = ({
  data: {
    content: { title, lead, heroImage, pathways, content, showPathways },
    globalConfig: { defaultPathways }
  },
  pageContext: { breadcrumbs },
  location
}) => {
  const params = queryString.parse(location.search)
  const order = params?.order || false
  const msg = params?.msg || false
  const allPathways = [...pathways, ...defaultPathways].slice(0, 3)

  return (
    <Layout>
      <Intro
        breadcrumbs={breadcrumbs}
        title={title}
        lead={lead}
        heroImage={heroImage}
      />
      <Block>
        <Container>
          <div style={{ textAlign: "center" }}>
            {order && <h3>{`Your order reference: #${order}`}</h3>}
            {msg && <p>{`${msg}`}</p>}
          </div>
        </Container>
      </Block>
      {content && (
        <Block>
          <ModuleArea modules={content} location={location} />
        </Block>
      )}
      {showPathways && (
        <Block padding="top" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
    </Layout>
  )
}

export default PostPurchase

export const query = graphql`
  query PostPurchaseQuery($language: String!) {
    content: datoCmsPostPurchase(locale: { eq: $language }) {
      title
      slug
      showTrustPilot
      showPathways
      showHeroImage
      showDisclaimer
      locale
      heroImage {
        alt
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "550"
            fit: "crop"
            w: "1170"
            q: 60
            auto: "format"
          }
        )
      }
      showHeroImage
      heroVideo {
        url
        title
        provider
        providerUid
        thumbnailUrl
        width
        height
      }
      lead
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pathways {
        ...Card
      }
      showPathways
      showTrustPilot
      ...PostPurchaseContentModuleArea
    }

    globalConfig: datoCmsGlobal(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`
