import React from "react"
import { Finder, LocationMap } from "@components"
import { graphql, useStaticQuery } from "gatsby"
import style from "./location-map-with-finder.mod.scss"

const LocationMapWithFinder = ({ showVirtualConsultation }) => {
  const {
    auShowroomList,
    nzShowroomList,
    auLocations,
    nzLocations
  } = useStaticQuery(
    graphql`
      query locationMapWithFilter {
        auShowroomList: allDatoCmsShowroomList(
          sort: { fields: position }
          filter: { country: { eq: "AU" }, locale: { eq: "en-AU" } }
        ) {
          nodes {
            id
            heading
            path
            postcodes
            locations {
              id
              title
              path
            }
          }
        }

        nzShowroomList: allDatoCmsShowroomList(
          sort: { fields: position }
          filter: { country: { eq: "NZ" }, locale: { eq: "en-NZ" } }
        ) {
          nodes {
            id
            heading
            path
            postcodes
            locations {
              id
              title
              path
            }
          }
        }

        nzLocations: allDatoCmsLocation(
          filter: { country: { eq: "NZ" }, locale: { eq: "en-NZ" } }
        ) {
          nodes {
            id
            areasServed
            path
            businessName
            vertical
            horizontal
            hidePin
            isJacuzzi: jacuzziExclusiveShowroom
          }
        }

        auLocations: allDatoCmsLocation(
          filter: { country: { eq: "AU" }, locale: { eq: "en-AU" } }
        ) {
          nodes {
            id
            areasServed
            path
            businessName
            vertical
            horizontal
            hidePin
            isJacuzzi: jacuzziExclusiveShowroom
          }
        }
      }
    `
  )

  const siteLanguage = process.env.GATSBY_LANGUAGE

  const showroomNodes =
    siteLanguage === "en-AU" ? auShowroomList.nodes : nzShowroomList.nodes

  const locationNodes =
    siteLanguage === "en-AU" ? auLocations.nodes : nzLocations.nodes

  const removeStates = new Set([
    "Western Australia",
    "ACT",
    "South Australia",
    "New South Wales",
    "Northern Territory",
    "Queensland",
    "Tasmania"
  ])

  const filteredShowrooms = showroomNodes.filter(
    obj => !removeStates.has(obj.heading)
  )

  return (
    <div className={style[`location-map-with-finder`]}>
      <Finder
        locationLists={filteredShowrooms}
        allLocations={showroomNodes}
        locations={locationNodes}
        showVirtualConsultation={showVirtualConsultation}
      >
        <LocationMap map={siteLanguage} locations={locationNodes} />
      </Finder>
    </div>
  )
}

export default LocationMapWithFinder
