import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./gallery-item.mod.scss"

const GalleryItem = ({
  image,
  onClick,
  elevated,
  hover,
  id,
  items,
  contentNode
}) => {
  const modifiers = []
  elevated && modifiers.push(style["gallery-item--elevated"])
  hover && modifiers.push(style["gallery-item--hover"])

  return (
    <div
      className={[style["gallery-item"], ...modifiers].join(" ")}
      onClick={() => onClick(id, items)}
    >
      <div className={style["gallery-item__image"]}>
        <GatsbyImage image={image.gatsbyImageData} loading={"lazy"} />
        {contentNode?.childMarkdownRemark?.html && (
          <div
            className={style["gallery-item__excerpt"]}
            dangerouslySetInnerHTML={{
              __html: contentNode?.childMarkdownRemark?.html
            }}
          />
        )}
      </div>
    </div>
  )
}

export default GalleryItem

export const query = graphql`
  fragment GalleryItem on DatoCmsGalleryItem {
    id
    product {
      name
      id
      path
      tags {
        slug
        id
        name
      }
      brand {
        name
        slug
      }
      category {
        slug
        title
      }
      productModel
    }
    country
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "544", fit: "crop", w: "736", q: 60, auto: "format" }
      )
      alt
    }
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    model {
      apiKey
    }
    video {
      url
      title
      provider
      providerUid
    }
    __typename
  }
`
