import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Layout from "@layouts/layout"
import { Intro, Container, Block, Embed } from "@components"
import { JsonLd } from "react-schemaorg"
import ModuleArea from "../helpers/moduleArea"

const Area = ({ data, pageContext }) => {
  const {
    id,
    title,
    seoMetaTags,
    leadNode,
    banner,
    path,
    tour,
    content,
    placeholder,
    showroom
  } = data.area
  // TODO - COMPLETE BREADCRUMBS
  const breadcrumbs = [
    {
      title: "Home",
      path: "/"
    },
    {
      title: title,
      path: path
    }
  ]
  // END TODO

  const birdeyeReviews = data.reviews

  const introConfig = {
    breadcrumbs: breadcrumbs,
    title,
    lead: leadNode.childMarkdownRemark.html
  }

  return (
    <Layout isJacuzzi={false}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block gutters>
        <Intro {...introConfig} banner={banner} rating={pageContext.rating} />
      </Block>
      <Container>
        {tour && (
          <Block gutters padding="double" heading={"Tour Our Virtual Showroom"}>
            <Embed
              placeholder={placeholder}
              iframeProps={{
                id: `plattar-${id}`,
                src: `${tour}&nt=1&title=0`,
                width: "100%",
                height: "100%",
                frameBorder: "0",
                allow: `xr-spatial-tracking`,
                allowFullScreen: `allowFullScreen`,
                title: "Tour Our Virtual Showroom"
              }}
              gtm={{
                event: `embedded-content-engagement`,
                label: `VR Embed Click`,
                value: `Tour Our Virtual Showroom`
              }}
            ></Embed>
          </Block>
        )}
      </Container>
      {content && (
        <Block padding="both">
          <ModuleArea
            modules={content}
            isJacuzzi={false}
            reviews={birdeyeReviews}
            showroom={showroom}
          />
        </Block>
      )}
    </Layout>
  )
}

export default Area

export const query = graphql`
  query areaById($id: String!, $birdeyeId: String!) {
    area: datoCmsArea(id: { eq: $id }) {
      id
      slug
      title
      lead
      tour
      showroom {
        birdeyeId
        primaryPhoneNumber
        salesPhoneNumber
        partsPhoneNumber
      }
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      path
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      placeholder {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "658"
            w: "1170"
            fit: "crop"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      banner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "175"
              w: "420"
              fit: "cover"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
      ...AreaModuleArea
    }
    reviews: allBirdeyeReview(filter: { businessId: { eq: $birdeyeId } }) {
      nodes {
        id
        businessId
        rating
        sourceType
        comments
        reviewDate
        businessName
        reviewer {
          name: nickName
        }
      }
    }
  }
  fragment AreaModuleArea on DatoCmsArea {
    __typename
    content {
      ... on DatoCmsAwardsCarousel {
        ...AwardsCarousel
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsFeaturedProductsGrid {
        ...FeaturedProductsGrid
      }
      ... on DatoCmsTestimonialSlider {
        ...TestimonialSlider
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsTour {
        ...TourEmbed
      }
      ... on DatoCmsReview {
        id
        title
        __typename
        model {
          apiKey
        }
      }
    }
  }
`
